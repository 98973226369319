import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';

import BulgarianFlag from 'styles/images/flags/bg.svg';
import VzechFlag from 'styles/images/flags/cz.svg';
import DenmarkFlag from 'styles/images/flags/dk.svg';
import GermanFlag from 'styles/images/flags/de.svg';
import UKFlag from 'styles/images/flags/gb.svg';
import SpainFlag from 'styles/images/flags/es.svg';
import EstioniaFlag from 'styles/images/flags/ee.svg';
import FinlandFlag from 'styles/images/flags/fi.svg';
import FrenchFlag from 'styles/images/flags/fr.svg';
import HungaryFlag from 'styles/images/flags/hu.svg';
import ItalyFlag  from 'styles/images/flags/it.svg';
import JapanFlag from 'styles/images/flags/jp.svg';
import NetherlandsFlag from 'styles/images/flags/nl.svg';
import NorwegianFlag  from 'styles/images/flags/no.svg';
import PolandFlag  from 'styles/images/flags/pl.svg';
import PortugueseFlog from 'styles/images/flags/pt.svg';
import RomaniaFlag from 'styles/images/flags/ro.svg';
import RussianFlag from 'styles/images/flags/ru.svg';
import SlovakFlag from 'styles/images/flags/sk.svg';
import SwedishFlag from 'styles/images/flags/se.svg';
import ChinaFlag from 'styles/images/flags/cn.svg';
import UkraineFlag from 'styles/images/flags/ua.svg';

const supportedApplicationLanguages = ["EN", "DE", "FR", "IT", "ES", "UA", "RU", "PL", "RO"];
const supportedPrintoutsLanguages = ["CS", "DA", "DE", "ET", "EN", "ES", "FR", "IT", "HU", "NL", "NO", "PL", "PT", "RO", "SK", "FI", "SV", "BG", "UK", "RU", "ZH", "JA"];


let supportedLanguages = [
    { languageCode: "BG", flagSVG: BulgarianFlag, nativeName: "Български", englishName: "Bulgarian" },
    { languageCode: "CS", flagSVG: VzechFlag, nativeName: "Čeština", englishName: "Czech" },
    { languageCode: "DA", flagSVG: DenmarkFlag, nativeName: "Dansk", englishName: "Danish" },
    { languageCode: "DE", flagSVG: GermanFlag, nativeName: "Deutsch", englishName: "German" },
    { languageCode: "EN", flagSVG: UKFlag, nativeName: "English", englishName: "English" },
    { languageCode: "ES", flagSVG: SpainFlag, nativeName: "Español", englishName: "Spanish" },
    { languageCode: "ET", flagSVG: EstioniaFlag, nativeName: "Eesti", englishName: "Estonian" },
    { languageCode: "FI", flagSVG: FinlandFlag, nativeName: "Suomi", englishName: "Finnish" },
    { languageCode: "FR", flagSVG: FrenchFlag, nativeName: "Français", englishName: "French" },
    { languageCode: "HU", flagSVG: HungaryFlag, nativeName: "Magyar", englishName: "Hungarian" },
    { languageCode: "IT", flagSVG: ItalyFlag, nativeName: "Italiano", englishName: "Italian" },
    { languageCode: "JA", flagSVG: JapanFlag, nativeName: "日本語 (日本)", englishName: "Japanese (Japan)" },
    { languageCode: "NL", flagSVG: NetherlandsFlag, nativeName: "Nederlands", englishName: "Dutch" },
    { languageCode: "NO", flagSVG: NorwegianFlag, nativeName: "Norsk", englishName: "Norwegian" },
    { languageCode: "PL", flagSVG: PolandFlag, nativeName: "Polski", englishName: "Polish" },
    { languageCode: "PT", flagSVG: PortugueseFlog, nativeName: "Português (Brasil)", englishName: "Portuguese (Brazil)" },
    { languageCode: "RO", flagSVG: RomaniaFlag, nativeName: "Română", englishName: "Romanian" },
    { languageCode: "UK", flagSVG: UkraineFlag, nativeName: "Українська", englishName: "Ukrainian" }, /*UK needs to be for printouts, since UK is set in the service as a culture name for printouts translations */
    { languageCode: "UA", flagSVG: UkraineFlag, nativeName: "Українська", englishName: "Ukrainian" }, /*UA needs to be fot application translations, since UA is set as a culture name for application translations */
    { languageCode: "RU", flagSVG: RussianFlag, nativeName: "Русский", englishName: "Russian" },
    { languageCode: "SK", flagSVG: SlovakFlag, nativeName: "Slovenčina", englishName: "Slovak" },
    { languageCode: "SV", flagSVG: SwedishFlag, nativeName: "Svenska", englishName: "Swedish" },
    { languageCode: "ZH", flagSVG: ChinaFlag, nativeName: "中文(简体)", englishName: "Chinese (Simplified)" },
];

const mapToFlags = (languages) => {
    return supportedLanguages.filter(x => languages.includes(x.languageCode));
};

const LanguageSelect = ({languages, value, onChange}) => {
    const languagesToDisplay = mapToFlags(languages);

    const handleChange = (e) => {
        if (onChange) {
            onChange(e.target.value);
        }
    };

    return (
        <Select
          variant="standard"
          value={value}
          onChange={handleChange} >
          <MenuItem value="cimode">Translation amdin</MenuItem>
            {languagesToDisplay.map(l => (<MenuItem key={l.languageCode} value={l.languageCode}><img src={l.flagSVG} width={20} />  {l.nativeName}</MenuItem>))}
        </Select>
    );
};

LanguageSelect.propTypes = {
    languages: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export { LanguageSelect as default,
     supportedApplicationLanguages,
     supportedPrintoutsLanguages
 };
