import * as type from 'constants/actionTypes';
import {globalpersistor} from '../store/configureStore';
import {USER_PROFILE_INIT} from '../constants/actionTypes';

export function errorResponse(response){
    const {data} = response;
    if(data && data.errors){
        if(Array.isArray(data.errors.msg)){
            return data.errors.msg.map(e => getErrorMessage(e.msg));
        } else {
            return [getErrorMessage(data.errors.msg)];
        }
    }
    return null;
}

const getErrorMessage = errorCode => {
    switch(errorCode){
        case 'WRONG_PASSWORD':
            return 'Username or password is invalid';
        case 'USER_NOT_VERIFIED':
            return 'User is not verified.';
        case 'BLOCKED_USER':
            return 'Your account has been temporarily blocked. \n\r Please try again in 5 minutes.';
        case 'PASSWORD_TOO_SHORT_MIN_5':
            return 'Password is too short (min 5 characters)';
        case 'EMAIL_IS_NOT_VALID':
            return 'Email is not valid!';
        case 'EMAIL_ALREADY_EXISTS':
            return 'Email is already registered!';
        case 'NOT_FOUND_OR_ALREADY_VERIFIED':
            return 'Email was not found or is already registered';
      case 'USER_NOT_REGISTERED':
            return 'User is not registered for DThermX. Please make a registration.';
        default:
            return `Error code: ${errorCode}`;
    }
};

export function logout(dispatch) {
    globalpersistor.purge();
    dispatch({type: type.Auth.SAGA_LOGOUT});
    dispatch({type: USER_PROFILE_INIT});
}
