import axios from 'axios';
import {
    CALCULATIONS_CALCULATE,
    CALCULATIONS_SAVED,
    CALCULATIONS_INPUTS,
    CALCULATIONS_INPUTS_TAGS_AUTOCOMPLETE,
    CALCULATIONS_SAVE,
    CALCULATIONS_DELETE,
    USERPROFILE_FILTER_SAVE,
    USERPROFILE_FILTER_RESET,
    USERPROFILE_DEFAULT_CALCULATION_INPUTS_SAVE,
    USERPROFILE_DEFAULT_CALCULATION_TYPE_CALCULATION_INPUTS_DELETE,
    USERPROFILE_DEFAULT_ALL_CALCULATION_INPUTS_DELETE,
    CALCULATION_OUTPUT_COLUMN_SETTINGS
} from '../constants/links';
import {dthermXBaseAddress} from './baseAddresses';

class CalculationsApi {

    static saveCalculationInputs(savedCalculationInputs) {
        if (!savedCalculationInputs) {
            return new Promise((resolve, reject) => {
                reject('Request validation error');
            });
        }

        return axios({
            method: 'POST',
            url:  `${dthermXBaseAddress()}${CALCULATIONS_SAVE}`,
            data: savedCalculationInputs
        });
    }

    static deleteCalculationInput(calculationInputId) {
        if (!calculationInputId) {
            return new Promise((resolve, reject) => {
                reject('Request validation error');
            });
        }

        return axios({
            method: 'GET',
            url:  `${dthermXBaseAddress()}${CALCULATIONS_DELETE}`,
            params:  {
                calculationInputId: calculationInputId
            }
        });
    }

    static saveDefaultCalculationInputs(calculationType, userCalculationInputs) {
      if (!calculationType || !userCalculationInputs) {
        return new Promise((resolve, reject) => {
          reject('Request validation error');
        });
      }

      return axios({
        method: 'POST',
        url:  `${dthermXBaseAddress()}${USERPROFILE_DEFAULT_CALCULATION_INPUTS_SAVE}`,
        data: {
          calculationType: calculationType,
          userCalculationInputs: userCalculationInputs
        }
      });
    }

    static deleteDefaultCalculationTypeCalculationInputs(calculationType) {
      if (!calculationType) {
        return new Promise((resolve, reject) => {
          reject('Request validation error');
        });
      }

      return axios({
        method: 'DELETE',
        url:  `${dthermXBaseAddress()}${USERPROFILE_DEFAULT_CALCULATION_TYPE_CALCULATION_INPUTS_DELETE}/${calculationType}`,
      });
    }

    static deleteAllDefaultCalculationInputs() {
      return axios({
        method: 'DELETE',
        url:  `${dthermXBaseAddress()}${USERPROFILE_DEFAULT_ALL_CALCULATION_INPUTS_DELETE}`
      });
  }


  static GetSavedCalculation() {
        return axios({
            method: 'GET',
            url:  `${dthermXBaseAddress()}${CALCULATIONS_SAVED}`,
        });
    }

    static getUserAutocomplete() {
        return axios({
            method: 'GET',
            url:  `${dthermXBaseAddress()}${CALCULATIONS_INPUTS_TAGS_AUTOCOMPLETE}`
        });
    }

    static getCalculationInputs(calculationType, calculationInputId = null) {
        if (!calculationType) {
            return new Promise((resolve, reject) => {
                reject('Request validation error');
            });
        }
        return axios({
            method: 'GET',
            url: `${dthermXBaseAddress()}${CALCULATIONS_INPUTS}`,
            params: {
                calculationType: calculationType,
                calculationInputId: calculationInputId
            }
        });
    }

    static saveFilterSettings(calculationFilters, targetCalculationType = null) {
        if (!calculationFilters) {
            return new Promise((resolve, reject) => {
                reject('Request validation error');
            });
        }

        return axios({
            method: 'POST',
            url:  `${dthermXBaseAddress()}${USERPROFILE_FILTER_SAVE}`,
            data: {
                calculationType: targetCalculationType,
                filters: calculationFilters
            }
        });
    }

    static resetFilterSettings(targetCalculationType = null) {
        return axios({
            method: 'POST',
            url:  `${dthermXBaseAddress()}${USERPROFILE_FILTER_RESET}`,
            data: {
                calculationType: targetCalculationType
            }
        });
    }

    static calculate(request){
        if(!request){
            return new Promise((resolve, reject) => {
                reject('Request validation error');
            });
        }
        return axios({ method: 'POST',
            url: `${dthermXBaseAddress()}${CALCULATIONS_CALCULATE}`,
            data: request
        });
    }

    static getCalculationColumnSettings(calculationType) {
      if(!calculationType){
        return new Promise((resolve, reject) => {
          reject('Request validation error');
        });
      }
      return axios({
        method: 'GET',
        url: `${dthermXBaseAddress()}${CALCULATION_OUTPUT_COLUMN_SETTINGS}`,
        params: {
          calculationType: calculationType
        }
      });
    }

    static saveCalculationColumnSettings(calculationType, userDefaultCalculationOutputColumns) {
      if(!calculationType || !userDefaultCalculationOutputColumns){
        return new Promise((resolve, reject) => {
          reject('Request validation error');
        });
      }
      return axios({
        method: 'POST',
        url: `${dthermXBaseAddress()}${CALCULATION_OUTPUT_COLUMN_SETTINGS}`,
        data: {
          calculationType: calculationType,
          calculationOutputColumns: userDefaultCalculationOutputColumns
        }
      });
    }

  static resetCalculationColumnSettings(calculationType) {
    if(!calculationType){
      return new Promise((resolve, reject) => {
        reject('Request validation error');
      });
    }
    return axios({ method: 'DELETE',
      url: `${dthermXBaseAddress()}${CALCULATION_OUTPUT_COLUMN_SETTINGS}`,
      data: {
        calculationType: calculationType
      }
    });
  }
}

export default CalculationsApi;
