import React, { useCallback, useState} from "react";
import CloneCalculationButton from "../common/CloneCalculationButton";
import OpenInNewTabButton from "../common/OpenInNewTabButton";
import PropTypes from "prop-types";
import { useDispatch, useSelector} from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from "utils/injectSaga";
import { getTralationKey } from 'utils/calculationParamsHelper';
import saga from 'containers/savedCalculationList/saga';
import reducer from 'containers/savedCalculationList/reducer';
import { makeSelectAutocompleteTags } from 'containers/savedCalculationList/selectors';
import CalculationInputsActions from "../calculation/inputForms/CalculationInputsActions";
import SaveCalculationInputsDialog from "../calculation/saveCalculationInputs/SaveCalculationInputsDialog";
import SavedCalculationInputsShareDialog from "../calculation/saveCalculationInputs/SavedCalculationInputsShareDialog";
import {useTranslation} from "react-i18next";
import OpenInMulticalcButton from "../common/OpenInMulticalcButton";
import {useParams} from 'react-router-dom';
import {
  resetCalculationFiltersToDefaultForAllCalculations,
  resetCalculationFiltersToDefaultForCalculationType,
  resetDefaultCalculationInputsForAllCalculations,
  resetDefaultCalculationInputsForCalculationType,
  saveCalculationFiltersAsDefaultForAllCalculations,
  saveCalculationFiltersAsDefaultForCalculationType,
  saveCalculationInputsAsDefaultForCalculationType,
  saveCalculationInputsToMyCalculations
} from '../../containers/calculations/actions';
import {generateCalculationPath} from '../../containers/App/RouterPaths';

const CalculationActions = ({
                              calculation,
                            }) => {
  useInjectSaga({ key: 'savedCalculationList', saga: saga });
  useInjectReducer({ key: 'savedCalculationList', reducer: reducer });
  const { calculationType, uuid } = useParams();

  const dispatch = useDispatch();
  const autocompleteTags = useSelector(makeSelectAutocompleteTags());

  const [isSaveCalculationDialogOpen, setIsSaveCalculationDialogOpen] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [url, setUrl] = useState("");
  const { t } = useTranslation();

  const onSubmitSaveCalculationDialog = useCallback((calculationInputs) => {
    setIsSaveCalculationDialogOpen(false);
    dispatch(saveCalculationInputsToMyCalculations(
      uuid,
      calculationInputs.customName,
      calculationInputs.note,
      calculationInputs.tags, (calculationInputs) => {
        setIsShareDialogOpen(true);
        const calculationPath = generateCalculationPath({
          calculationType: calculationInputs.userCalculationInputs.calculationType.toLowerCase(),
          uuid: calculationInputs.id
        });
        const url = `${window.location.origin}${calculationPath}`;
        setUrl(url);
      }
    ));
  }, [dispatch, uuid]);

  const handleSaveAsDefaultForCalculationType = useCallback(() => {
    dispatch(saveCalculationInputsAsDefaultForCalculationType(uuid));
  }, [uuid, dispatch]);

  const handleSaveToMyCalculations = useCallback(()=> {
    setIsSaveCalculationDialogOpen(true);
  }, []);

  const handleResetForCalculationType = useCallback(() => {
    dispatch(resetDefaultCalculationInputsForCalculationType(uuid));
  }, [dispatch, uuid]);

  const handleResetForAllCalculationTypes= useCallback(() => {
    dispatch(resetDefaultCalculationInputsForAllCalculations(uuid));
  }, [dispatch, uuid]);

  const handleFilterSaveToThisCalculationType = useCallback(() => {
    dispatch(saveCalculationFiltersAsDefaultForCalculationType(uuid));
  }, [dispatch, uuid]);

  const handleFilterResetThisCalculationType = useCallback(() => {
    dispatch(resetCalculationFiltersToDefaultForCalculationType(uuid));
  }, [dispatch, uuid]);

  const handleSaveFilterToAllCalculationTypes = useCallback(() => {
    dispatch(saveCalculationFiltersAsDefaultForAllCalculations(uuid));
  }, [dispatch, uuid]);

  const handleResetFilterForAllCalculationTypes = useCallback(() => {
    dispatch(resetCalculationFiltersToDefaultForAllCalculations(uuid));
  }, [dispatch, uuid]);

  return (
    <div className="d-flex justify-content-end align-items-center pb-4">
      <OpenInMulticalcButton path={`/${calculationType.toLowerCase()}`}  calculation={calculation} />
      <CloneCalculationButton path={generateCalculationPath({
        calculationType: calculationType.toLowerCase(),
        uuid: "clone"
      })+"?uuid="+uuid}/>
      <OpenInNewTabButton path={generateCalculationPath({
        calculationType: calculationType.toLowerCase(),
        uuid: "new"
      })} title="Open in new tab" />
      <CalculationInputsActions
        onResetForCalculationType={handleResetForCalculationType}
        onSaveToCalculationType={handleSaveAsDefaultForCalculationType}
        onSaveToMyCalculations={handleSaveToMyCalculations}
        onResetForAllCalculationTypes={handleResetForAllCalculationTypes}
        onSaveFilterToCalculationType={handleFilterSaveToThisCalculationType}
        onResetFilterToCalculationType={handleFilterResetThisCalculationType}
        onSaveFilterToAllCalculations={handleSaveFilterToAllCalculationTypes}
        onResetFilterToAllCalculations={handleResetFilterForAllCalculationTypes}/>

      {isSaveCalculationDialogOpen && <SaveCalculationInputsDialog
        initialData={{
          customName: "",
          note: "",
          tags: [t(getTralationKey(calculationType))]
        }}
        autocompleteTags={autocompleteTags}
        isOpen={isSaveCalculationDialogOpen}
        onSubmit={onSubmitSaveCalculationDialog}
        onCancel={() => setIsSaveCalculationDialogOpen(false)} />
      }

      {isShareDialogOpen && <SavedCalculationInputsShareDialog
        url={url}
        isOpen={isShareDialogOpen}
        onClose={() => setIsShareDialogOpen(false)} />}
    </div>
  );
};

CalculationActions.propTypes = {
  calculationType: PropTypes.string.isRequired,
  calculation: PropTypes.object,
};

export default React.memo(CalculationActions);
