import React from 'react';
import HomePage from 'containers/Home/Loadable';
import NotFoundPage from 'components/NotFoundPage';
import SavedCalculationList from 'containers/savedCalculationList/Loadable';
import MulticalcSavedCalculationsPage from 'containers/multicalc/savedCalculations/Loadable';
import FluidProperties from 'containers/fluidProperties/Loadable';
import FluidEditor from "containers/fluidEditor/Loadable";
import PressureDropTool from "containers/pressureDropTool/Loadable";
import CipTool from 'containers/cipTool/Loadable';
import ProductSelector from 'containers/productSelector/Loadable';
import ProductBasket from 'components/ProductBasket/Loadable';
import SettingsPage from 'containers/settings/Loadable';
import MulticalcPage from 'containers/multicalc/Loadable';
import LoginPage from '../Account/LoginPage';
import CxPortalLoginPage from '../Account/CxPortal/LoginPage';
import CxPortalLogoutPage from '../Account/CxPortal/LogoutPage';
/*import ForgottenPassword from 'containers/Login/ForgottenPassword';
import Register from 'containers/Login/Register';*/
// import VerifyUser from 'containers/Login/VerifyUser';
import XRefPage from '../../containers/xReference/Loadable';
import EasySelect from 'containers/EasySelect/Loadable';
import EasySelectTapWater from 'containers/EasySelect/TapWater/Loadable';
import EasySelectHeating from 'containers/EasySelect/Heating/Loadable';
import {
  Route,
  createRoutesFromElements,
  createBrowserRouter
} from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import {RouterPaths} from "./RouterPaths";
import CalculationsBootstrapper from '../calculations/CalculationsBootstrapper';
import CalculationResults from '../calculations/Components/CalculationResults';
import CalculationResultsDetails from '../calculations/Components/CalculationResultsDetails.jsx';
import SharedLayout from './SharedLayout';
import PrivateLayout from './PrivateLayout';
import PublicLayout from './PublicLayout';
import App from './Loadable';

const router = createBrowserRouter(createRoutesFromElements(
  <Route id="root" element={<App/>}>
    <Route element={<PublicLayout/>}>
      <Route path={RouterPaths.FORGOTTENPASSWORD} lazy= {async () => {
        const c = await import('../Account/ForgottenPasswordPage');
        return { Component: c.default };
      }}/>
      <Route path={RouterPaths.RESET_PASSWORD} lazy={async () => {
        const c = await import('../Account/ResetPasswordPage');
        return { Component: c.default };
      }}/>
      <Route path={RouterPaths.REGISTER} lazy={async () => {
        const c = await import('../Account/RegisterPage');
        return { Component: c.default };
      }} />
      <Route path={`${RouterPaths.CONFIRM_EMAIL}`} lazy={async () => {
        const c = await import('../Account/ConfirmEmailPage');
        return { Component: c.default };
      }} />
      <Route path={RouterPaths.LOGIN} element={<LoginPage />}/>
      <Route path={RouterPaths.LOGIN_CX_PORTAL} element={<CxPortalLoginPage />}/>
      <Route path={RouterPaths.LOGOUT_CX_PORTAL} element={<CxPortalLogoutPage />}/>
    </Route>
    <Route element={<SharedLayout/>}>
      <Route path={RouterPaths.XREFERENCE} element={<XRefPage />}>
        <Route exact path=":paramManufacturer?" element={<XRefPage />}>
          <Route exact path=":paramModel" element={<XRefPage />}>
            <Route exact path=":paramNop" element={<XRefPage />} />
          </Route>
        </Route>
      </Route>
    </Route>
    <Route element={<PrivateRoute />}>
      <Route element={<PrivateLayout/>}>
        <Route path={RouterPaths.HOME} element={<HomePage />} />
        <Route path={RouterPaths.SAVEDCALCULATIONS} element={<SavedCalculationList />}/>
        <Route path={RouterPaths.CALCULATIONS} element={<CalculationsBootstrapper/>}>
          <Route path={RouterPaths.CALCULATION_RESULT} element={<CalculationResults/>}>
            <Route path={RouterPaths.CALCULATION_RESULT_DETAILS} element={<CalculationResultsDetails/>} />
          </Route>
        </Route>
        <Route path={RouterPaths.SETTINGS} element={<SettingsPage />} />
        <Route path={RouterPaths.PRODUCTSELECTOR} element={<ProductSelector />}>
          <Route path={RouterPaths.PRODUCTSELECTOR_ARTICLES} element={<ProductSelector/>}>
            <Route path={RouterPaths.PRODUCTSELECTOR_ARTICLES_SELECTED_ARTICLE} element={<ProductSelector/>}/>
          </Route>
          <Route path={RouterPaths.PRODUCTSELECTOR_XPCS} element={<ProductSelector/>}>
            <Route path={RouterPaths.PRODUCTSELECTOR_XPCS_SELECTED_XPC} element={<ProductSelector/>}/>
          </Route>
        </Route>
        <Route path={RouterPaths.PRODUCTBASKET} element={<ProductBasket />}/>
        <Route path={RouterPaths.FLUIDPROPERTIES} element={<FluidProperties />}>
          <Route path=":fluidId" element={<FluidProperties />}>
            <Route path=":amount" element={<FluidProperties />}>
              <Route path=":unit" element={<FluidProperties />}>
                <Route path=":isGas" element={<FluidProperties />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path={`${RouterPaths.FLUIDPROPERTIES}/:fluidId/:amount//:isGas`} element={<FluidProperties />} />
        <Route path={RouterPaths.FLUID_EDITOR} element={<FluidEditor />}/>
        <Route path={RouterPaths.PRESSURE_DROP_TOOL} element={<PressureDropTool />}/>
        <Route path={RouterPaths.CIP_TOOL} element={<CipTool />}/>
        <Route path={RouterPaths.MULTICALC} element={<MulticalcPage />}>
          <Route path=":calcId" element={<MulticalcPage />} />
        </Route>
        <Route path={RouterPaths.MULTICALCSAVEDCALCULATIONS} element={<MulticalcSavedCalculationsPage />}/>
      </Route>
    </Route>
    <Route path={RouterPaths.EASYSELECT} element={<EasySelect />}/>
    <Route path={RouterPaths.EASYSELECT_TAPWATER} element={<EasySelectTapWater />}/>
    <Route path={RouterPaths.EASYSELECT_HEATING} element={<EasySelectHeating />}/>
    <Route path="*" element={<NotFoundPage />}/>
  </Route>
));


export default router;
