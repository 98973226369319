import {all, call, put, takeLatest,} from 'redux-saga/effects';
import * as constants from 'constants/actionTypes';
import {authenticateStatus, confirmEmail, forgotPassword, login, logout, register, resetPassword} from './api';
import userProfileApi from 'api/userProfileApi';
import {enqueueSnackbar} from "../Notifier/actions";
import {errorResponse} from '../../actions/applicationActions';
import {appInsights} from '../../applicationInsights';
import {
  ACCOUNT_CONFIRM_EMAIL, ACCOUNT_FORGOTTEN_PASSWORD,
  ACCOUNT_REGISTER,
  ACCOUNT_RESET_PASSWORD,
  AUTHENTICATE_STATUS,
  LOGIN,
  LOGOUT
} from './contants';
import {
  authenticateStatusError,
  authenticateStatusSuccess,
  confirmEmailError,
  confirmEmailSuccess, forgottenPasswordError, forgottenPasswordSuccess, loginError,
  loginSuccess, logoutError, logoutSuccess, registerError, registerSuccess,
  resetPasswordError,
  resetPasswordSuccess
} from './actions';

export function* loginSaga(action) {
  try {
    yield call(login, action.username, action.password);
    yield put(loginSuccess());
  } catch (error) {
    yield put(loginError(error.response.data));
  }
}

export function* logoutSaga() {
  try {
    yield call(logout);
    yield put(logoutSuccess());
  } catch (error) {
    yield put(logoutError(error.response.data));
  }
}

export function* authenticateStatusSaga() {
  try {
    yield call(authenticateStatus);
    yield put(authenticateStatusSuccess());
  } catch (error) {
    yield put(authenticateStatusError(error.response.data));
  }
}

export function* forgottenPasswordSaga(action) {
  try {
    yield call(forgotPassword, action.email);
    yield put(forgottenPasswordSuccess());
    action.successCallback('Email was sent to your email address. Please check your inbox');
  } catch (e) {
    yield put(forgottenPasswordError(e));
    const errors = errorResponse(e.response);
    const eMsg = errors ? errors : [`Password reset error: ${e}`];
    action.errorCallback(eMsg);

    appInsights.trackException({
      exception: e,
      properties: {
        message: 'Reset password error',
      },
    });
  }
}

export function* registerSaga(action) {
  try {
    yield call(register, action.registerData);
    yield put(registerSuccess());
    action.successCallback('Registration successful! Please check your email and confirm your email address to complete the registration process.');
  } catch (e) {
    yield put(registerError(e.response.data));
    const errors = e.response.data[''] || [];
    action.errorCallback(errors);

    appInsights.trackException({
      exception: e,
      properties: {
        message: 'Registering error',
      },
    });
  }
}

export function* getCountriesSaga() {
  try {
    const response = yield call(userProfileApi.getCountries);
    yield put({
      type: constants.Constants.REDUX_LOADCOUNTRIES, countries: response.data.map(c => {
        return {
          country: c.countryName,
          salesContact: c.salesContact
        };
      })
    });
  } catch (e) {
    yield put(enqueueSnackbar({message: "Error loading countries", options: {variant: "error"}}));
    appInsights.trackException({
      exception: e,
      properties: {
        message: 'Loading countries error',
      },
    });
  }
}

export function* resetPasswordSaga(action) {
  try {
    yield call(resetPassword, action.email, action.resetCode, action.newPassword);
    yield put(resetPasswordSuccess());
  } catch (e) {
    const errors = Object.values(e.response.data.errors).flat();
    yield put(resetPasswordError(errors));

    appInsights.trackException({
      exception: e,
      properties: {
        message: 'Forgotten password error',
      },
    });
  }
}

export function* confirmEmailSaga(action) {
  try {
    yield call(confirmEmail, action.userId, action.code);
    yield put(confirmEmailSuccess());
  } catch (e) {
    yield put(confirmEmailError(e.message));

    appInsights.trackException({
      exception: e,
      properties: {
        message: 'Confirm email address error',
      },
    });
  }
}

export default function* authFunctions() {
  yield all([
    takeLatest(LOGIN, loginSaga),
    takeLatest(LOGOUT, logoutSaga),
    takeLatest(AUTHENTICATE_STATUS, authenticateStatusSaga),
    takeLatest(ACCOUNT_REGISTER, registerSaga),
    takeLatest(constants.Constants.SAGA_LOADCOUNTRIES, getCountriesSaga),
    takeLatest(ACCOUNT_FORGOTTEN_PASSWORD, forgottenPasswordSaga),
    takeLatest(ACCOUNT_RESET_PASSWORD, resetPasswordSaga),
    takeLatest(ACCOUNT_CONFIRM_EMAIL, confirmEmailSaga)
  ]);
}

export const SagaKey = "account";
