import  React, { useMemo, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useParams } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from 'react-i18next';
import * as validations from '../../../validations/hypertwainValidator';
import FluidSelect from 'components/fluidSelector/FluidSelect';
import FluidInputType from "components/fluidSelector/FluidInputType.ts";
import FlowTypeInput from 'components/common/FlowTypeInput';
import HeatExchangerSelect from 'components/HeatExchangerSelect';
import HeatLoadQuantityInput from 'components/common/QuantityInputs/HeatLoadQuantityInput';
import TemperatureQuantityInput from 'components/common/QuantityInputs/TemperatureQuantityInput';
import FlowQuantityInput from 'components/common/QuantityInputs/FlowQuantityInput';
import PressureQuantityInput from 'components/common/QuantityInputs/PressureQuantityInput';
import NumericInput  from 'components/common/NumericInput';
import CalculationMethodSelector from "../../CalculationMethodSelector/CalculationMethodSelector";
import FluidPropertiesButton from "../../common/FluidPropertiesButton";
import CalculationActions from "../../CalculationActions/CalculationActions";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../../../applicationInsights";
import useTrackPageVisit from "../../common/hooks/useTrackPageVisit";
import TemperatureDifferenceQuantityInput from "../../common/QuantityInputs/TemperatureDifferenceQuantityInput";

const tabIndexes = {
  calculationMethod: 1,
  selectedFluidSide1: 2,
  selectedFluidSide2: 3,
  coCurrent: 4,
  selectedHeatExchangers: 5,
  heatLoad: 6,
  subCooledLiquidTemperature: 7,
  inletTemperatureSide2: 8,
  evaporationTemperature: 9,
  outletVaporQuality: 10,
  superHeatingTemperature: 11,
  outletTemperatureSide2: 12,
  flowSide2: 13,
  maxPressureDropSide2: 14,
  numberOfPlates: 15,
};

const HypertwainInputs = ({ calculationData, onChange, setFormValidity, onReloadHeatExchangers, hideButtons, onCalcMethodChange}) => {
  useTrackPageVisit(calculationData.calculationType);
  const userInputs = calculationData.userCalculationInputs;
  const {t} = useTranslation();
  const params = useParams();

  useEffect(() => {
    if (params.calculation && params.calculation !== userInputs.calculationMethod) {
      onChange([{fieldName: "calculationMethod", value: params.calculation}]);
    }
  }, [params.calculation, userInputs.calculationMethod, onChange]);
  useEffect(() => {
    onReloadHeatExchangers(["HYPERTWAIN"], userInputs.coCurrent, userInputs.selectedFluidSide1, userInputs.selectedFluidSide2);
  }, [userInputs.coCurrent, userInputs.selectedFluidSide1, userInputs.selectedFluidSide2, onReloadHeatExchangers]);

  const handleCalculationInputFieldChange = useCallback((fieldName, value) => onChange([{
    fieldName: fieldName,
    value: value
  }]), [onChange]);
  const validation = useMemo(() => validations.validateForm(userInputs.calculationMethod, calculationData),
    [calculationData, userInputs.calculationMethod]);

  useEffect(() => {
    setFormValidity(validation.isValid);
  }, [validation.isValid, setFormValidity]);

  return (
        <div className="calc-input mb-3">
          {!hideButtons &&
            <CalculationActions
            calculationType={calculationData.calculationType}
            calculation={calculationData}/>
          }
          <div className="me-3 ms-3">
            <CalculationMethodSelector
              tabIndex={tabIndexes.calculationMethod}
              availableMethods={calculationData.availableCalculationMethods}
              value={userInputs.calculationMethod}
              onChange={onCalcMethodChange}/>
          </div>
            <br />
            <div className="calc-params-table">
              <div className="row">
                <div className="col-12 col-sm-3 label pe-2 pe-sm-0">{t("CalcForm_FluidSide1")} <FluidPropertiesButton size={12} selectedFluid={userInputs.selectedFluidSide1} /></div>
                <div className="col-12 col-sm-9">
                  <FluidSelect
                    tabIndex={tabIndexes.selectedFluidSide1}
                    fluids={calculationData.availableFluids}
                    value={userInputs.selectedFluidSide1}
                    onFluidChange={useCallback((fluid) => handleCalculationInputFieldChange("selectedFluidSide1", fluid), [handleCalculationInputFieldChange])}
                    isValid={validation.selectedFluidSide1.isValid}
                    messages={validation.selectedFluidSide1.validationMessages}
                    fluidInputType={FluidInputType.SinglePhase}/>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-3 label pe-2 pe-sm-0">{t("CalcForm_FluidSide2")} <FluidPropertiesButton size={12} selectedFluid={userInputs.selectedFluidSide2} /></div>
                <div className="col-12 col-sm-9">
                  <FluidSelect
                    tabIndex={tabIndexes.selectedFluidSide2}
                    fluids={calculationData.availableFluids}
                    value={userInputs.selectedFluidSide2}
                    onFluidChange={useCallback((fluid) => handleCalculationInputFieldChange("selectedFluidSide2", fluid), [handleCalculationInputFieldChange])}
                    isValid={validation.selectedFluidSide2.isValid}
                    messages={validation.selectedFluidSide2.validationMessages}
                    fluidInputType={FluidInputType.SinglePhase}/>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-3 label">{t("CalcForm_FlowType")}</div>
                <div className="col-12 col-sm-9 single-value">
                  <FlowTypeInput
                    tabIndex={tabIndexes.coCurrent}
                    coCurrent={userInputs.coCurrent}
                    onChange={useCallback((value) => handleCalculationInputFieldChange("coCurrent", value), [handleCalculationInputFieldChange])}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-3 label">{t("CalcForm_Exchangers")}</div>
                <div className="col-12 col-sm-9 single-value">
                  <HeatExchangerSelect
                    tabIndex={tabIndexes.selectedHeatExchangers}
                    exchangers={calculationData.availableHeatExchangers}
                    name="selectedHeatExchangers"
                    value={userInputs.selectedHeatExchangers}
                    onChange={useCallback((i, selectedHeatExchangers) => handleCalculationInputFieldChange("selectedHeatExchangers", selectedHeatExchangers), [handleCalculationInputFieldChange])}
                    isValid={validation.selectedHeatExchangers.isValid}
                    messages={validation.selectedHeatExchangers.validationMessages}
                    isSingleSelect={userInputs.calculationMethod.toLowerCase() !== "design"}
                  />
                </div>
              </div>
            </div>
            <br/>
          <div className="calc-params-table">
            <div className="row header-row">
              <div className="col-sm-4"/>
              <div className="col-6 col-sm-4">{t("CalcForm_Side1")}</div>
              <div className="col-6 col-sm-4">{t("CalcForm_Side2")}</div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 label">{t("CalcForm_HeatLoad")}</div>
              <div className="col-12 col-sm-8 single-value">
                <div className="in-wrapper heatload">
                  <HeatLoadQuantityInput
                    tabIndex={tabIndexes.heatLoad}
                    quantityInput={userInputs.heatLoad}
                    onChange={useCallback((quantityInput) => handleCalculationInputFieldChange("heatLoad", quantityInput), [handleCalculationInputFieldChange])}
                    isValid={validation.heatLoad.isValid}
                    messages={validation.heatLoad.validationMessages}/>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 label">{t("CalcForm_SubcooledLiqTemp")}</div>
              <div className="col-6 col-sm-4 value">
                <TemperatureQuantityInput
                    tabIndex={tabIndexes.subCooledLiquidTemperature}
                    quantityInput={userInputs.subCooledLiquidTemperature}
                    onChange={useCallback((quantityInput) => handleCalculationInputFieldChange("subCooledLiquidTemperature", quantityInput), [handleCalculationInputFieldChange])}
                    isValid={validation.subCooledLiquidTemperature.isValid}
                    messages={validation.subCooledLiquidTemperature.validationMessages}/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 label">{t("CalcForm_InTemp")}</div>
              <div className="col-6 col-sm-4 value"/>
              <div className="col-6 col-sm-4 value">
                <TemperatureQuantityInput
                  tabIndex={tabIndexes.inletTemperatureSide2}
                  quantityInput={userInputs.inletTemperatureSide2}
                  onChange={useCallback((quantityInput) => handleCalculationInputFieldChange("inletTemperatureSide2", quantityInput), [handleCalculationInputFieldChange])}
                  isValid={validation.inletTemperatureSide2.isValid}
                  messages={validation.inletTemperatureSide2.validationMessages}/>
              </div>
            </div>
            <div className="row">
              <div
                className="col-12 col-sm-4 label">{t('Calculation.EvaporatorDual.Inputs.EvaporationTemperature', 'Evap. temp.(dew)')}</div>
              <div className="col-6 col-sm-4 value">
                <TemperatureQuantityInput
                  tabIndex={tabIndexes.evaporationTemperature}
                  quantityInput={userInputs.evaporationTemperature}
                  onChange={useCallback((quantityInput) => handleCalculationInputFieldChange("evaporationTemperature", quantityInput), [handleCalculationInputFieldChange])}
                  isValid={validation.evaporationTemperature.isValid}
                  messages={validation.evaporationTemperature.validationMessages}/>
              </div>
              <div className="col-6 col-sm-4 value"/>
            </div>
            <div className="row">
              <div
                className="col-12 col-sm-4 label">{t('Calculation.Evaporator.Inputs.OutletVaporQuality', 'Outlet vapor quality')}</div>
              <div className="col-6 col-sm-4 value">
                <NumericInput
                  tabIndex={tabIndexes.outletVaporQuality}
                  amount={userInputs.outletVaporQuality}
                  onChange={useCallback((amount) => handleCalculationInputFieldChange("outletVaporQuality", amount), [handleCalculationInputFieldChange])}
                  isValid={validation.outletVaporQuality.isValid}
                  messages={validation.outletVaporQuality.validationMessages}
                  min={0} max={1} step={0.01}/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 label">{t("SuperHeating")}</div>
              <div className="col-6 col-sm-4 value">
                <TemperatureDifferenceQuantityInput
                  tabIndex={tabIndexes.superHeatingTemperature}
                  quantityInput={userInputs.superHeatingTemperature}
                  onChange={useCallback((quantityInput) => handleCalculationInputFieldChange("superHeatingTemperature", quantityInput), [handleCalculationInputFieldChange])}
                  isValid={validation.superHeatingTemperature.isValid}
                  messages={validation.superHeatingTemperature.validationMessages}/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 label">{t("CalcForm_OutTemp")}</div>
              <div className="col-6 col-sm-4 value"/>
              <div className="col-6 col-sm-4 value">
                <TemperatureQuantityInput
                  tabIndex={tabIndexes.outletTemperatureSide2}
                  quantityInput={userInputs.outletTemperatureSide2}
                  onChange={useCallback((quantityInput) => handleCalculationInputFieldChange("outletTemperatureSide2", quantityInput), [handleCalculationInputFieldChange])}
                  isValid={validation.outletTemperatureSide2.isValid}
                  messages={validation.outletTemperatureSide2.validationMessages}/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 label">{t("CalcForm_Flow")}</div>
              <div className="col-6 col-sm-4 value" />
              <div className="col-6 col-sm-4 value">
                <FlowQuantityInput
                  tabIndex={tabIndexes.flowSide2}
                  quantityInput={userInputs.flowSide2}
                  fluid={userInputs.selectedFluidSide2}
                  fluidTemperatures={useMemo(() => [userInputs.inletTemperatureSide2, userInputs.outletTemperatureSide2], [userInputs.inletTemperatureSide2, userInputs.outletTemperatureSide2])}
                  onChange={useCallback((quantityInput) => handleCalculationInputFieldChange("flowSide2", quantityInput), [handleCalculationInputFieldChange])}
                  isValid={validation.flowSide2.isValid}
                  messages={validation.flowSide2.validationMessages}/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 label">{t("CalcForm_MaxPressureDrop")}</div>
              <div className="col-6 col-sm-4 value" />
              <div className="col-6 col-sm-4 value">
                <PressureQuantityInput
                  tabIndex={tabIndexes.maxPressureDropSide2}
                  quantityInput={userInputs.maxPressureDropSide2}
                  onChange={useCallback((quantityInput) => handleCalculationInputFieldChange("maxPressureDropSide2", quantityInput), [handleCalculationInputFieldChange])}
                  isValid={validation.maxPressureDropSide2.isValid}
                  messages={validation.maxPressureDropSide2.validationMessages}/>
              </div>
            </div>
            <div className={clsx('row', {'hide': validation.numberOfPlates.hidden })}>
                <div className="col-12 col-sm-4 label">{t("CalcForm_NoPlates")}</div>
                <div className="col-12 col-sm-8 single-value">
                  <NumericInput
                    tabIndex={tabIndexes.numberOfPlates}
                    amount={userInputs.numberOfPlates}
                    onChange={useCallback((amount) => handleCalculationInputFieldChange("numberOfPlates", amount), [handleCalculationInputFieldChange])}
                    isValid={validation.numberOfPlates.isValid}
                    messages={validation.numberOfPlates.validationMessages}
                    disabled={validation.numberOfPlates.disabled} />
                </div>
            </div>
          </div>
        </div>
  );
};

HypertwainInputs.propTypes = {
  calculationData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  setFormValidity: PropTypes.func.isRequired,
  onReloadHeatExchangers: PropTypes.func.isRequired,
  onCalcMethodChange: PropTypes.func.isRequired,
  hideButtons: PropTypes.bool
};

export default compose(
  memo
)(withAITracking(reactPlugin, HypertwainInputs, "HypertwainInputs"));
