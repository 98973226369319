import React from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";

const UnitSystemSelect = ({selected, onChange}) => {
    const { t } = useTranslation();
    const handleChange = (event) => onChange(event.target.value);

    return (
        <Select variant="standard" value={selected} onChange={handleChange}>
          <MenuItem key="Metric" value="Metric">{t('Application.UnitSystem.Metric', 'Metric')}</MenuItem>
          <MenuItem key="US" value="US">{t('Application.UnitSystem.US', 'US')}</MenuItem>
        </Select>
    );
};

UnitSystemSelect.propTypes = {
    selected: PropTypes.oneOf(['Metric', 'US']),
    onChange: PropTypes.func.isRequired
};

UnitSystemSelect.defaultProps = {
    selected: "Metric"
};

export default UnitSystemSelect;
