import { produce } from 'immer';
import {
  CALCULATION_ADD,
  CALCULATION_INPUTS_LOAD,
  CALCULATION_INPUTS_LOAD_SUCCESS,
  CALCULATION_INPUTS_LOAD_ERROR,
  CALCULATION_INPUTS_CHANGE,
  CALCULATION_SET_VALIDITY,
  CALCULATION_RELOAD_HEAT_EXCHANGERS_SUCCESS,
  CALCULATION_FILTERS_CHANGE,
  CALCULATION_CALCULATE,
  CALCULATION_CALCULATE_SUCCESS,
  CALCULATION_CALCULATE_ERROR,
  CALCULATION_SELECTED_RESULT_CHANGE,
  CALCULATION_SELECTED_SUB_RESULT_CHANGE,
  CALCULATION_FROM_BASKET_SUCCESS
} from './constants';
import {saveCalculations} from './DexieJsDBApi';
export const initialState = {
  calculations: []
};

export const createNewCalculation = (uuid) => {
  return {
    uuid: uuid,
    timestampCreated: new Date(),
    loading: false,
    error: false,
    inputParams: null,
    response: null,
    isCalculating: false,
    calculationError: false,
    selectedExchanger: null,
    selectedSubResult: null,
    isValid: true
  };
};

export default (state = initialState, action) => {
  const newState = produce(state, draft => {
    // keep only the last 2 calculations
    if (draft.calculations.length >= 2) {
      draft.calculations.shift();
    }

    switch (action.type) {
      case CALCULATION_ADD: {
        // replace calculation by uuid
        const index = draft.calculations.findIndex(x => x.uuid === action.calculation.uuid);
        if (index >= 0) {
          draft.calculations.splice(index, 1);
        }

        draft.calculations.push(action.calculation);
        break;
      }
      case CALCULATION_INPUTS_LOAD: {
        const calculation = draft.calculations.find(x => x.uuid === action.uuid);
        if (calculation) {
          calculation.loading = true;
          calculation.error = false;
        } else {
          const newCalculation = createNewCalculation(action.uuid);
          newCalculation.loading = true;
          newCalculation.error = false;
          draft.calculations.push(newCalculation);
        }
        break;
      }
      case CALCULATION_INPUTS_LOAD_SUCCESS:{
        const calculation = draft.calculations.find(x => x.uuid === action.uuid);
        calculation.loading = false;
        calculation.error = false;
        calculation.inputParams = action.inputs;
        break;
      }
      case CALCULATION_INPUTS_LOAD_ERROR: {
        const calculation = draft.calculations.find(x => x.uuid === action.uuid);
        calculation.loading = false;
        calculation.error = action.error;
        calculation.inputParams = null;
        break;
      }
      case CALCULATION_INPUTS_CHANGE: {
        const calculation = draft.calculations.find(x => x.uuid === action.uuid);
        const values = action.values || [];
        values.forEach(o => {
          calculation.inputParams.userCalculationInputs[o.fieldName] = o.value;
        });
        break;
      }
      case CALCULATION_SET_VALIDITY: {
        const calculation = draft.calculations.find(x => x.uuid === action.uuid);
        calculation.isValid = action.isValid;
        break;
      }
      case CALCULATION_RELOAD_HEAT_EXCHANGERS_SUCCESS: {
        const calculation = draft.calculations.find(x => x.uuid === action.uuid);
        calculation.inputParams.availableHeatExchangers = action.heatExchangers;
        break;
      }
      case CALCULATION_FILTERS_CHANGE: {
        const calculation = draft.calculations.find(x => x.uuid === action.uuid);
        calculation.inputParams.filterSettings[action.filterName] = action.selections;
        break;
      }
      case CALCULATION_CALCULATE: {
        const calculation = draft.calculations.find(x => x.uuid === action.uuid);
        calculation.isCalculating = true;
        calculation.calculationError = false;
        calculation.response = null;
        calculation.selectedExchanger = null;
        calculation.selectedSubResult = null;
        break;
      }
      case CALCULATION_CALCULATE_SUCCESS: {
        const calculation = draft.calculations.find(x => x.uuid === action.uuid);
        calculation.isCalculating = false;
        calculation.calculationError = false;
        calculation.response = action.data;
        break;
      }
      case CALCULATION_CALCULATE_ERROR: {
        const calculation = draft.calculations.find(x => x.uuid === action.uuid);
        calculation.isCalculating = false;
        calculation.calculationError = action.error;
        calculation.response = null;
        break;
      }
      case CALCULATION_SELECTED_RESULT_CHANGE: {
        const calculation = draft.calculations.find(x => x.uuid === action.uuid);
        calculation.selectedExchanger = action.selectedResult;
        break;
      }
      case CALCULATION_SELECTED_SUB_RESULT_CHANGE: {
        const calculation = draft.calculations.find(x => x.uuid === action.uuid);
        calculation.selectedExchanger = action.selectedResult;
        calculation.selectedSubResult = action.selectedSubResult;
        break;
      }
      case CALCULATION_FROM_BASKET_SUCCESS: {
        const basketCalculation = action.basketCalculation;
        const newCalculation = createNewCalculation(action.uuid);
        newCalculation.inputParams = basketCalculation.calculation.inputParams;
        newCalculation.selectedExchanger = basketCalculation.selectedProduct;
        newCalculation.response = {
          inutParams: basketCalculation.inputParams,
        };
        draft.calculations.push(newCalculation);
        break;
      }
    }
  });

  // save the state to localforage
  saveCalculations(newState.calculations);

  return newState;
};
