export default {
  headerType: 'FULL',
  application: {
    logout: false,
    error: null
  },
  unitConversion: {},
  loadItems: {
    fluids: {
      singlePhase: []
    },
    countries: []
  },
  calculations: {},
  filtersData: {
    factories: [],
    materials: [],
    pressures: [],
    warehouses: [],
    defaultSelection: {}
  },
  userProfile: {
      email: '',
      userId: null,
      firstName: '',
      lastName: '',
      packages: [],
      features: [],
      filterSettings: {
        defaultCalculationFilterSettings: {},
        calculationTypeFilterSettings: {}
      },
      settings: {
        workingLanguage: "EN",
        printouts: {
          arePrintoutsShortened: false,
          languagePrimary: "EN",
          languageSecondary: null
        },
        units: "Metric"
      },
    allowedCalculationTypes: [],
    allowedPrintoutFormats: []
  },
  connections: {
    isOpen: false,
    loaders: {
      calculation: false,
      filter: false
    },
    filters: {
        material: [],
        pressure: [],
        portConfigurations: []
    },
    connections: {},
    calculationType: "",
    initialPortConfig: "",
    selectedArticle: null,
    connectionsData: []
  },
  multicalc: {
    inputs: {},
    calculations: {},
    calculationType: '',
    loadingSavedCalculations: false,
    savedCalculations: [],
    exportConfigurations: {}
  }
};
