import React from 'react';
import PropTypes from 'prop-types';
import {IconButton} from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';
import Link from '@mui/material/Link';


const OpenInNewTabButton = ({ tabIndex, path, size  }) => {

  return (
  <IconButton aria-describedby={"filters-actions"} size={size} tabIndex={tabIndex} title={"Open in new tab"}>
    <Link href={path} color="textSecondary" target="_blank">
      <LaunchIcon/>
    </Link>
  </IconButton>
  );
};

OpenInNewTabButton.propTypes = {
  tabIndex: PropTypes.number,
  path: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"])
};

export default OpenInNewTabButton;
