import React from 'react';
import PropTypes from 'prop-types';
import {IconLab} from "components/Icons";
import {useTranslation} from "react-i18next";

const FluidPropertiesButton = ({ selectedFluid, size }) => {
  const { t } = useTranslation();

  const {fluidId, isGas} = selectedFluid;
  const amount = selectedFluid.inputValue ? selectedFluid.inputValue.amount: 0;
  const unit = selectedFluid.inputValue ? encodeURI(selectedFluid.inputValue.unit): '';
  const href = `/fluidProperties/${fluidId}/${amount}/${unit}/${isGas === 'gas'}`;

  return (
    <a href={href} target="_blank" rel="noreferrer" className="btn-fluid-props" title={t("HamburgerFluidProperties")}>
      <IconLab size={size}/>
    </a>
  );
};

FluidPropertiesButton.propTypes = {
  size: PropTypes.number,
  selectedFluid: PropTypes.object
};

export default React.memo(FluidPropertiesButton);
