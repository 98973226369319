export enum UnitSystem {
  Metric = "Metric",
  US = "US"
}

export const UnitGroupsNames = {
  Temperatures: 'Temperatures',
  Flows: 'Flows',
  MassFlows: 'MassFlows',
  HeatLoads: 'Heat loads',
  Pressure: 'Pressure',
  FoulingFactors: 'Fouling factors',
  Mass: 'Mass',
  Time: 'Time',
  Area: 'Area',
  Viscosity: 'Viscosity',
  KinematicViscosity: 'Kinematic Viscosity',
  Density: 'Density',
  HeatCapacity: 'Heat capacity',
  ThermalConductivity: 'Thermal conductivity',
  HeatTransferCoefficient: 'Heat Transfer Coefficient',
  Velocity: 'Velocity',
  Length: 'Length',
  Volume: 'Volume',
  LatentHeat: 'Latent heat',
  Percentage: 'Percentage',
  MolWeight: 'MolWeight',
  AbsPressure: 'Abs Pressure',
  TemperatureDifferances: 'Temperature Differances',
  Integer: 'Integer',
  HeatFlux: 'HeatFlux'
};

export const HeatLoad = {
  W: 'W',
  kW: 'kW',
  kpm_s:'kpm/s',
  kcal_s:'kcal/s',
  kcal_h:'kcal/h',
  ft_lbf_s:'ft*lbf/s',
  Btu_h:'Btu/h',
  USRT:'USRT'
};

export const Temperature = {
  C: '°C',
  F: '°F',
  K: 'K'
};

export const TemperatureDifference = {
  F: '°F',
  K: 'K'
};

export const Pressure =  {
  kPa : 'kPa',
  Pa : 'Pa',
  bar : 'bar',
  atm : 'atm',
  psi : 'psi',
  mwc : 'mwc'
};

export const FoulingFactor = {
  m_C_kW: 'm²,°C/kW',
  m_h_C_Mcal: 'm²,h,°C/Mcal',
  sqrft_h_F_Btu: 'sqrft,h,°F/Btu'
};

export const Flow = {
  kg_s: 'kg/s',
  kg_h : 'kg/h',
  l_s : 'l/s',
  l_min : 'l/min',
  l_h: 'l/h',
  mmm_h : 'm³/h',
  lb_h : 'lb/h',
  ft_h : 'ft³/h',
  US_gph : 'US gph',
  US_gpm : 'US gpm',
  scfm : 'scfm',
  Nl_min : 'Nl/min',
  Nl_s : 'Nl/s',
  Nm_h : 'Nm³/h',
  Nm_min : 'Nm³/min'
};

export const MassFlow = {
  kg_s: 'kg/s',
  kg_h : 'kg/h',
  lb_h : 'lb/h',
};

export const Percentage = {
  percent: '%'
};

export const Mass = {
  kg: 'kg',
  g: 'g',
  ton: 'ton',
  lb: 'lb'
};

export const Time = {
  s: 's',
  min: 'min',
  h: 'h'
};

export const Area = {
  m2: 'm²',
  sqrft: 'sqrft'
};

export const Viscosity = {
  cP: 'cP',
  Ns_m2: 'Ns/m²'
};

export const KinematicViscosity = {
  cSt: 'cSt',
  St: 'St',
  m2_s: 'm²/s'
};

export const Density = {
  kg_m3: 'kg/m³',
  lb_cuft: 'lb/cuft'
};

export const HeatCapacity = {
  J_kg_C: 'J/kg,°C',
  kJ_kg_C: 'kJ/kg,°C',
  Btu_lb_F: 'Btu/lb,°F'
};

export const ThermalConductivity = {
  W_m_C: 'W/m,°C',
  Btu_ft_h_F: 'Btu/ft,h,°F'
};

export const HeatTransferCoefficient = {
  W_m_C: 'W/m²,°C',
  Btu_sqrft_h_F: 'Btu/sqrft,h,°F'
};

export const Velocity = {
  m_s: 'm/s',
  ft_s: 'ft/s'
};

export const Length = {
  mm: 'mm',
  in: 'in'
};

export const Volume = {
  dm3: 'dm³',
  cuft: 'cuft',
  cm3: 'cm³',
  m3: 'm³',
  USgallon: 'US gallon'
};

export const LatentHeat = {
  J_kg: 'J/kg',
  kJ_kg: 'kJ/kg',
  Btu_lb: 'Btu/lb'
};

export const MolWeight = {
  g_mol: 'g/mol',
  lbm_kmol: 'lbm/kmol'
};

export const AbsPressure = {
  bar_a: 'bar(a)',
  bar_g: 'bar(g)',
  psi_a: 'psi(a)',
  psi_g: 'psi(g)'
};

export const HeatFlux = {
  W_m2: 'W/m²',
  kW_m2: 'kW/m²',
  Btu_h_sqrft: 'Btu/h,sqrft',
  Btu_h_m2: 'Btu/h,m²'
};

export const HeatLoadUnitsPrecisions = new Map([
  [HeatLoad.W, { name: HeatLoad.W, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 100 }],
  [HeatLoad.kW, { name: HeatLoad.kW, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }],
  [HeatLoad.kpm_s, { name: HeatLoad.kpm_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 100 }],
  [HeatLoad.kcal_s, { name: HeatLoad.kcal_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }],
  [HeatLoad.kcal_h, { name: HeatLoad.kcal_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1000 }],
  [HeatLoad.ft_lbf_s, { name: HeatLoad.ft_lbf_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1000 }],
  [HeatLoad.Btu_h, { name: HeatLoad.Btu_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1000 }],
  [HeatLoad.USRT, { name: HeatLoad.USRT, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }]
]);

export const TemperatureUnitsPrecisions = new Map([
  [Temperature.C, { name: Temperature.C, signDigits: 5, maxDecimals: 1, minValue: -273, maxValue: Number.MAX_VALUE, increment: 1 }],
  [Temperature.F, { name: Temperature.F, signDigits: 5, maxDecimals: 1, minValue: -459, maxValue: Number.MAX_VALUE, increment: 1 }],
  [Temperature.K, { name: Temperature.K, signDigits: 5, maxDecimals: 1, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }]
]);

export const TemperatureDifferenceUnitsPrecisions = new Map([
  [Temperature.F, { name: Temperature.F, signDigits: 5, maxDecimals: 1, minValue: -459, maxValue: Number.MAX_VALUE, increment: 1 }],
  [Temperature.K, { name: Temperature.K, signDigits: 5, maxDecimals: 1, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }]
]);

export const PressureUnitsPrecisions = new Map([
  [Pressure.kPa, { name: Pressure.kPa, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }],
  [Pressure.Pa, { name: Pressure.Pa, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 100 }],
  [Pressure.bar, { name: Pressure.bar, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
  [Pressure.atm, { name: Pressure.atm, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
  [Pressure.psi, { name: Pressure.psi, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
  [Pressure.mwc, { name: Pressure.mwc, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
]);

export const AbsPressureUnitsPrecisions = new Map([
  [AbsPressure.bar_a, { name: AbsPressure.bar_a, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }],
  [AbsPressure.bar_g, { name: AbsPressure.bar_g, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 100 }],
  [AbsPressure.psi_a, { name: AbsPressure.psi_a, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
  [AbsPressure.psi_g, { name: AbsPressure.psi_g, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
]);

export const FoulingFactorUnitsPrecision = new Map([
  [FoulingFactor.m_C_kW, { name: FoulingFactor.m_C_kW, signDigits: 4, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }],
  [FoulingFactor.m_h_C_Mcal, { name: FoulingFactor.m_h_C_Mcal, signDigits: 4, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }],
  [FoulingFactor.sqrft_h_F_Btu, { name: FoulingFactor.sqrft_h_F_Btu, signDigits: 4, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }]
]);

export const FlowUnitsPrecision = new Map([
  [Flow.kg_s, { name: Flow.kg_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
  [Flow.kg_h, { name: Flow.kg_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 100 }],
  [Flow.l_s, { name: Flow.l_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
  [Flow.l_min, { name: Flow.l_min, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }],
  [Flow.l_h, { name: Flow.l_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }],
  [Flow.mmm_h, { name: Flow.mmm_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }],
  [Flow.lb_h, { name: Flow.lb_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
  [Flow.ft_h, { name: Flow.ft_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 100 }],
  [Flow.US_gph, { name: Flow.US_gph, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 10 }],
  [Flow.US_gpm, { name: Flow.US_gpm, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 100 }],
  [Flow.scfm, { name: Flow.scfm, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }],
  [Flow.Nl_min, { name: Flow.Nl_min, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
  [Flow.Nl_s, { name: Flow.Nl_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 100 }],
  [Flow.Nm_h, { name: Flow.Nm_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 10 }],
  [Flow.Nm_min, { name: Flow.Nm_min, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
]);

export const MassFlowUnitsPrecision = new Map([
  [Flow.kg_s, { name: Flow.kg_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
  [Flow.kg_h, { name: Flow.kg_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 100 }],
  [Flow.l_s, { name: Flow.l_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
  [Flow.l_min, { name: Flow.l_min, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }],
  [Flow.mmm_h, { name: Flow.mmm_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }],
  [Flow.lb_h, { name: Flow.lb_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
  [Flow.ft_h, { name: Flow.ft_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 100 }],
  [Flow.US_gph, { name: Flow.US_gph, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 10 }],
  [Flow.US_gpm, { name: Flow.US_gpm, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 100 }],
  [Flow.scfm, { name: Flow.scfm, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1 }],
  [Flow.Nl_min, { name: Flow.Nl_min, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
  [Flow.Nl_s, { name: Flow.Nl_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 100 }],
  [Flow.Nm_h, { name: Flow.Nm_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 10 }],
  [Flow.Nm_min, { name: Flow.Nm_min, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.MAX_VALUE, increment: 0.1 }],
]);

export const PercentageUnitsPrecision = new Map([
  [Percentage.percent, { name: Percentage.percent, signDigits: 3, maxDecimals: 1, minValue: -1000, maxValue: 1000, increment: 1 }]
]);

export const MolWeightUnitsPrecision = new Map([
  [MolWeight.g_mol, {name: MolWeight.g_mol, signDigits: 3, maxDecimals: 6, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
  [MolWeight.lbm_kmol, {name: MolWeight.lbm_kmol, signDigits: 3, maxDecimals: 6, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
]);

export const VelocityUnitsPrecision = new Map([
  [Velocity.m_s, {name: Velocity.m_s, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
  [Velocity.ft_s, {name: Velocity.ft_s, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
]);

export const DensityUnitsPrecisions = new Map([
  [Density.kg_m3, {name: Density.kg_m3, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
  [Density.lb_cuft, {name: Density.lb_cuft, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
]);

export const HeatCapacityUnitsPrecisions = new Map([
  [HeatCapacity.Btu_lb_F, {name: HeatCapacity.Btu_lb_F, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
  [HeatCapacity.J_kg_C, {name: HeatCapacity.J_kg_C, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
  [HeatCapacity.kJ_kg_C, {name: HeatCapacity.kJ_kg_C, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
]);

export const ThermalConductivityUnitsPrecisions = new Map([
  [ThermalConductivity.Btu_ft_h_F, {name: ThermalConductivity.Btu_ft_h_F, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
  [ThermalConductivity.W_m_C, {name: ThermalConductivity.W_m_C, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
]);

export const ViscosityUnitsPrecisions = new Map([
  [Viscosity.cP, {name: Viscosity.cP, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
  [Viscosity.Ns_m2, {name: Viscosity.Ns_m2, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
]);

export const KinematicViscosityUnitsPrecisions = new Map([
  [KinematicViscosity.cSt, {name: KinematicViscosity.cSt, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
  [KinematicViscosity.m2_s, {name: KinematicViscosity.m2_s, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
  [KinematicViscosity.St, {name: KinematicViscosity.St, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
]);

export const LatentHeatUnitsPrecisions = new Map([
  [LatentHeat.Btu_lb, {name: LatentHeat.Btu_lb, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
  [LatentHeat.kJ_kg, {name: LatentHeat.kJ_kg, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
  [LatentHeat.J_kg, {name: LatentHeat.J_kg, signDigits: 3, maxDecimals: 2, minValue: 0, maxValue: Number.MAX_VALUE, increment: 1}],
]);

const UnitGroupsData = [
    {
       Name: UnitGroupsNames.Temperatures,
       SignDigits: 4,
       Offset: 300,
       Unit: [
          {
             Name: Temperature.C,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: Temperature.F,
             Quantity: -32,
             Multiplier: 5/9,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          },
          {
             Name: Temperature.K,
             Quantity: -273.15,
             Multiplier: 1,
             MeasuringSystem: "Both",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          }
       ]
    },
    {
       Name: UnitGroupsNames.Flows,
       SignDigits: 4,
       Offset: 0,
       Unit: [
          {
             Name: Flow.kg_s,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: Flow.kg_h,
             Quantity: 0,
             Multiplier: 0.000277778,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Flow.l_s,
             Quantity: 0,
             Multiplier: 0.001,
             MeasuringSystem: "Metric",
             DensityCalculation: "Temperature",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Flow.l_h,
             Quantity: 0,
             Multiplier: 2.777778E-07,
             MeasuringSystem: "Metric",
             DensityCalculation: "Temperature",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Flow.l_min,
             Quantity: 0,
             Multiplier: 1.666667E-05,
             MeasuringSystem: "Metric",
             DensityCalculation: "Temperature",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Flow.mmm_h,
             Quantity: 0,
             Multiplier: 0.0002777778,
             MeasuringSystem: "Metric",
             DensityCalculation: "Temperature",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Flow.lb_h,
             Quantity: 0,
             Multiplier: 0.000125998,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Flow.ft_h,
             Quantity: 0,
             Multiplier: 7.86579E-06,
             MeasuringSystem: "US",
             DensityCalculation: "Temperature",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Flow.US_gph,
             Quantity: 0,
             Multiplier: 1.051503E-06,
             MeasuringSystem: "US",
             DensityCalculation: "Temperature",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Flow.US_gpm,
             Quantity: 0,
             Multiplier: 6.309021E-05,
             MeasuringSystem: "US",
             DensityCalculation: "Temperature",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          },
          {
             Name: Flow.scfm,
             Quantity: 0,
             Multiplier: 0.00047195,
             MeasuringSystem: "US",
             DensityCalculation: "Normal",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Flow.Nl_min,
             Quantity: 0,
             Multiplier: 1.666667E-05,
             MeasuringSystem: "Metric",
             DensityCalculation: "Normal",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Flow.Nl_s,
             Quantity: 0,
             Multiplier: 0.001,
             MeasuringSystem: "Metric",
             DensityCalculation: "Normal",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Flow.Nm_h,
             Quantity: 0,
             Multiplier: 0.000277778,
             MeasuringSystem: "Metric",
             DensityCalculation: "Normal",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Flow.Nm_min,
             Quantity: 0,
             Multiplier: 0.01666667,
             MeasuringSystem: "Metric",
             DensityCalculation: "Normal",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          }
       ]
    },
    {
       Name: UnitGroupsNames.MassFlows,
       SignDigits: 4,
       Offset: 0,
       Unit: [
          {
             Name: MassFlow.kg_s,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: MassFlow.kg_h,
             Quantity: 0,
             Multiplier: 0.000277778,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: MassFlow.lb_h,
             Quantity: 0,
             Multiplier: 0.000125998,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          }
       ]
    },
    {
       Name: UnitGroupsNames.HeatLoads,
       SignDigits: 4,
       Offset: 0,
       Unit: [
          {
             Name: HeatLoad.kW,
             Quantity: 0,
             Multiplier: 1000,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: HeatLoad.kpm_s,
             Quantity: 0,
             Multiplier: 9.80665,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: HeatLoad.kcal_s,
             Quantity: 0,
             Multiplier: 4186.8,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: HeatLoad.kcal_h,
             Quantity: 0,
             Multiplier: 1.163,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: HeatLoad.ft_lbf_s,
             Quantity: 0,
             Multiplier: 1.35582,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: HeatLoad.Btu_h,
             Quantity: 0,
             Multiplier: 0.293071,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          },
          {
             Name: HeatLoad.USRT,
             Quantity: 0,
             Multiplier: 3516.85,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: HeatLoad.W,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: false,
             USDefault: false
          }
       ]
    },
    {
       Name: UnitGroupsNames.Pressure,
       SignDigits: 3,
       Offset: 0,
       Unit: [
          {
             Name: Pressure.kPa,
             Quantity: 0,
             Multiplier: 1000,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: Pressure.Pa,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Pressure.bar,
             Quantity: 0,
             Multiplier: 100000,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Pressure.atm,
             Quantity: 0,
             Multiplier: 101325,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Pressure.psi,
             Quantity: 0,
             Multiplier: 6894.76,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          },
          {
             Name: Pressure.mwc,
             Quantity: 0,
             Multiplier: 9806.65,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          }
       ]
    },
    {
       Name: UnitGroupsNames.FoulingFactors,
       SignDigits: 4,
       Offset: 1.1,
       Unit: [
          {
             Name: FoulingFactor.m_C_kW,
             Quantity: 0,
             Multiplier: 0.001,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: FoulingFactor.m_h_C_Mcal,
             Quantity: 0,
             Multiplier: 0.00859845,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: FoulingFactor.sqrft_h_F_Btu,
             Quantity: 0,
             Multiplier: 0.17611,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          }
       ]
    },
    {
       Name: UnitGroupsNames.Mass,
       SignDigits: 3,
       Offset: 0,
       Unit: [
          {
             Name: Mass.kg,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: Mass.g,
             Quantity: 0,
             Multiplier: 0.001,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Mass.ton,
             Quantity: 0,
             Multiplier: 1000,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Mass.lb,
             Quantity: 0,
             Multiplier: 0.453592,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          }
       ]
    },
    {
       Name: UnitGroupsNames.Time,
       SignDigits: 3,
       Offset: 0,
       Unit: [
          {
             Name: Time.s,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Both",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: true
          },
          {
             Name: Time.min,
             Quantity: 0,
             Multiplier: 60,
             MeasuringSystem: "Both",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Time.h,
             Quantity: 0,
             Multiplier: 3600,
             MeasuringSystem: "Both",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          }
       ]
    },
    {
       Name: UnitGroupsNames.Area,
       SignDigits: 3,
       Offset: 0,
       Unit: [
          {
             Name: Area.m2,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: Area.sqrft,
             Quantity: 0,
             Multiplier: 0.092903,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          }
       ]
    },
    {
       Name: UnitGroupsNames.Viscosity,
       SignDigits: 3,
       Offset: 0,
       Unit: [
          {
             Name: Viscosity.cP,
             Quantity: 0,
             Multiplier: 0.001,
             MeasuringSystem: "Both",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: true,
             USDefault: true
          },
          {
             Name: Viscosity.Ns_m2,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Both",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: false,
             USDefault: false
          }
       ]
    },
    {
       Name: UnitGroupsNames.KinematicViscosity,
       SignDigits: 5,
       Offset: 0,
       Unit: [
          {
             Name: KinematicViscosity.cSt,
             Quantity: 0,
             Multiplier: 0.000001,
             MeasuringSystem: "Both",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: true,
             USDefault: true
          },
          {
             Name: KinematicViscosity.St,
             Quantity: 0,
             Multiplier: 0.0001,
             MeasuringSystem: "Both",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: KinematicViscosity.m2_s,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Both",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: false,
             USDefault: false
          }
       ]
    },
    {
       Name: UnitGroupsNames.Density,
       SignDigits: 4,
       Offset: 0,
       Unit: [
          {
             Name: Density.kg_m3,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: Density.lb_cuft,
             Quantity: 0,
             Multiplier: 16.0185,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          }
       ]
    },
    {
       Name: UnitGroupsNames.HeatCapacity,
       SignDigits: 4,
       Offset: 0,
       Unit: [
          {
             Name: HeatCapacity.J_kg_C,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: HeatCapacity.kJ_kg_C,
             Quantity: 0,
             Multiplier: 1000,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: HeatCapacity.Btu_lb_F,
             Quantity: 0,
             Multiplier: 4186.8,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          }
       ]
    },
    {
       Name: UnitGroupsNames.ThermalConductivity,
       SignDigits: 4,
       Offset: 0,
       Unit: [
          {
             Name: ThermalConductivity.W_m_C,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: ThermalConductivity.Btu_ft_h_F,
             Quantity: 0,
             Multiplier: 1.73073,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          }
       ]
    },
    {
       Name: UnitGroupsNames.HeatTransferCoefficient,
       SignDigits: 3,
       Offset: 0,
       Unit: [
          {
             Name: HeatTransferCoefficient.W_m_C,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: HeatTransferCoefficient.Btu_sqrft_h_F,
             Quantity: 0,
             Multiplier: 5.67826,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          }
       ]
    },
    {
       Name: UnitGroupsNames.Velocity,
       SignDigits: 3,
       Offset: 0,
       Unit: [
          {
             Name: Velocity.m_s,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: Velocity.ft_s,
             Quantity: 0,
             Multiplier: 0.3048,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          }
       ]
    },
    {
       Name: UnitGroupsNames.Length,
       SignDigits: 3,
       Offset: 0,
       Unit: [
          {
             Name: Length.mm,
             Quantity: 0,
             Multiplier: 0.001,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: Length.in,
             Quantity: 0,
             Multiplier: 0.0254,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          }
       ]
    },
    {
       Name: UnitGroupsNames.Volume,
       SignDigits: 3,
       Offset: 0,
       Unit: [
          {
             Name: Volume.dm3,
             Quantity: 0,
             Multiplier: 0.001,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: Volume.cuft,
             Quantity: 0,
             Multiplier: 0.0283168,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          },
          {
             Name: Volume.cm3,
             Quantity: 0,
             Multiplier: 1E-06,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Volume.m3,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: Volume.USgallon,
             Quantity: 0,
             Multiplier: 0.00378541,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          }
       ]
    },
    {
       Name: UnitGroupsNames.LatentHeat,
       SignDigits: 4,
       Offset: 0,
       Unit: [
          {
             Name: LatentHeat.J_kg,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: LatentHeat.kJ_kg,
             Quantity: 0,
             Multiplier: 1000,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: LatentHeat.Btu_lb,
             Quantity: 0,
             Multiplier: 2326,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          }
       ]
    },
    {
       Name: UnitGroupsNames.Percentage,
       SignDigits: 3,
       Offset: 500,
       Unit: [
           {
            Name: Percentage.percent,
            Quantity: 0,
            Multiplier: 1,
            MeasuringSystem: "Both",
            DensityCalculation: "None",
            SIUnit: true,
            MetricDefault: true,
            USDefault: true
            }
        ]
    },
    {
       Name: UnitGroupsNames.MolWeight,
       SignDigits: 4,
       Offset: 0,
       Unit: [
          {
             Name: MolWeight.g_mol,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: MolWeight.lbm_kmol,
             Quantity: 0,
             Multiplier: 0.453,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          }
       ]
    },
    {
       Name: UnitGroupsNames.AbsPressure,
       SignDigits: 3,
       Offset: 0,
       Unit: [
          {
             Name: AbsPressure.bar_a,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          },
          {
             Name: AbsPressure.bar_g,
             Quantity: 1,
             Multiplier: 1,
             MeasuringSystem: "Metric",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          },
          {
             Name: AbsPressure.psi_a,
             Quantity: 0,
             Multiplier: 0.068966,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          },
          {
             Name: AbsPressure.psi_g,
             Quantity: 14.5,
             Multiplier: 0.068966,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: false
          }
       ]
    },
    {
       Name: UnitGroupsNames.TemperatureDifferances,
       SignDigits: 4,
       Offset: 300,
       Unit: [
          {
             Name: TemperatureDifference.F,
             Quantity: 0,
             Multiplier: 0.555556,
             MeasuringSystem: "US",
             DensityCalculation: "None",
             SIUnit: false,
             MetricDefault: false,
             USDefault: true
          },
          {
             Name: TemperatureDifference.K,
             Quantity: 0,
             Multiplier: 1,
             MeasuringSystem: "Both",
             DensityCalculation: "None",
             SIUnit: true,
             MetricDefault: true,
             USDefault: false
          }
       ]
    },
    {
       Name: UnitGroupsNames.Integer,
       SignDigits: 3,
       Offset: 100,
       Unit: [
           {
                Name: "",
                Quantity: 0,
                Multiplier: 1,
                MeasuringSystem: "Both",
                DensityCalculation: "None",
                SIUnit: true,
                MetricDefault: true,
                USDefault: true
            }
        ]
    },
    {
      Name: UnitGroupsNames.HeatFlux,
      SignDigits: 3,
      Offset: 1,
      Unit: [
        {
          Name: HeatFlux.W_m2,
          Quantity: 0,
          Multiplier: 1,
          MeasuringSystem: "Both",
          DensityCalculation: "None",
          SIUnit: true,
          MetricDefault: false,
          USDefault: false
        },
        {
          Name: HeatFlux.kW_m2,
          Quantity: 0,
          Multiplier: 1000,
          MeasuringSystem: "Both",
          DensityCalculation: "None",
          SIUnit: false,
          MetricDefault: true,
          USDefault: false
        },
        {
          Name: HeatFlux.Btu_h_sqrft,
          Quantity: 0,
          Multiplier: 3.154591347965082,
          MeasuringSystem: "Both",
          DensityCalculation: "None",
          SIUnit: false,
          MetricDefault: false,
          USDefault: true
        },
        {
          Name: HeatFlux.Btu_h_m2,
          Quantity: 0,
          Multiplier: 0.293071,
          MeasuringSystem: "Both",
          DensityCalculation: "None",
          SIUnit: false,
          MetricDefault: false,
          USDefault: false
        }
      ]
    }
 ];

const UnitGroupPrecisionsMap = new Map([
  [UnitGroupsNames.HeatLoads, HeatLoadUnitsPrecisions],
  [UnitGroupsNames.Temperatures, TemperatureUnitsPrecisions],
  [UnitGroupsNames.TemperatureDifferances, TemperatureDifferenceUnitsPrecisions],
  [UnitGroupsNames.Pressure, PressureUnitsPrecisions],
  [UnitGroupsNames.AbsPressure, AbsPressureUnitsPrecisions],
  [UnitGroupsNames.FoulingFactors, FoulingFactorUnitsPrecision],
  [UnitGroupsNames.Flows, FlowUnitsPrecision],
  [UnitGroupsNames.MassFlows, MassFlowUnitsPrecision],
  [UnitGroupsNames.Percentage, PercentageUnitsPrecision],
  [UnitGroupsNames.MolWeight, MolWeightUnitsPrecision],
  [UnitGroupsNames.Velocity, VelocityUnitsPrecision],
  [UnitGroupsNames.Density, DensityUnitsPrecisions],
  [UnitGroupsNames.HeatCapacity, HeatCapacityUnitsPrecisions],
  [UnitGroupsNames.ThermalConductivity, ThermalConductivityUnitsPrecisions],
  [UnitGroupsNames.Viscosity, ViscosityUnitsPrecisions],
  [UnitGroupsNames.KinematicViscosity, KinematicViscosityUnitsPrecisions],
  [UnitGroupsNames.LatentHeat, LatentHeatUnitsPrecisions],
]);

class Densities {
  private readonly _fluidDensityForNormalBasedUnit: number;
  private readonly _fluidDensityForTemperatureBasedUnit: number;

  public get fluidDensityForNormalBasedUnit(): number {
    return this._fluidDensityForNormalBasedUnit;
  }

  public get fluidDensityForTemperatureBasedUnit(): number {
    return this._fluidDensityForTemperatureBasedUnit;
  }
}

 class Unit {
    private readonly _name: string;
    private readonly _quantity: number;
    private readonly _multiplier: number;
    private readonly _measuringSystem: MeasuringSystem;
    private readonly _densityCalculation: string;
    private readonly _siUnit: boolean;
    private readonly _metricDefault: boolean;
    private readonly _usDefault: boolean;

    constructor(name: string, quantity: number, multiplier: number, measuringSystem: MeasuringSystem, densityCalculation: string, siUnit: boolean, metricDefault: boolean, usDefault: boolean) {
        this._name = name;
        this._quantity = quantity;
        this._multiplier = multiplier;
        this._measuringSystem = measuringSystem;
        this._densityCalculation = densityCalculation;
        this._siUnit = siUnit;
        this._metricDefault = metricDefault;
        this._usDefault = usDefault;
    }

    public get name(): string {
        return this._name;
    }

    public get quantity(): number {
        return this._quantity;
    }

    public get multiplier(): number {
        return this._multiplier;
    }

    public get measuringSystem(): MeasuringSystem {
        return this._measuringSystem;
    }

    public get densityCalculation(): string {
        return this._densityCalculation;
    }

    public get siUnit(): boolean {
        return this._siUnit;
    }

    public get metricDefault(): boolean {
        return this._metricDefault;
    }

    public get usDefault(): boolean {
        return this._usDefault;
    }

    public convertToSI(value: number, densities?: Densities): number {
      switch (this.densityCalculation) {
        case "None":
          return (value + this._quantity) * this._multiplier;
        case "Temperature":
          return (value + this._quantity) * this._multiplier * densities.fluidDensityForTemperatureBasedUnit;
        case "Normal":
          return (value + this._quantity) * this._multiplier * densities.fluidDensityForNormalBasedUnit;
      }
    }

    public convertFromSI(value: number, densities?: Densities): number {
      switch (this.densityCalculation) {
        case "None":
          return (value / this._multiplier) - this._quantity;
        case "Temperature":
          return ((value / this._multiplier) - this._quantity) / densities.fluidDensityForTemperatureBasedUnit;
        case "Normal":
          return ((value / this._multiplier) - this._quantity) / densities.fluidDensityForNormalBasedUnit;
      }
    }
}

 class UnitGroup {
    private readonly _name: string;
    private readonly _signDigits: number;
    private readonly _offset: number;
    private readonly _units: Array<Unit>;

    constructor(name: string, signDigits: number, offset: number, units: Array<Unit>){
        this._name = name;
        this._signDigits = signDigits;
        this._offset = offset;
        this._units = units;
    }

    public get name(): string {
        return this._name;
    }

    public get signDigits(): number {
        return this._signDigits;
    }

    public get offset(): number {
        return this._offset;
    }

    public get units(): Array<Unit> {
        return this._units;
    }

    public get SIUnit(): Unit {
        return this._units.find(x => x.siUnit === true);
    }

    public existsUnit(unitName: string): boolean {
        return this._units.filter(x => x.name == unitName).length != 0;
    }

    public getUnit(unitName: string): Unit {
        return this._units.find(x => x.name == unitName);
    }

    public getUnitsByMeasuringSystem(measuringSystem: MeasuringSystem): Array<Unit> {
      return this._units.filter(x => x.measuringSystem === measuringSystem);
    }

    public getUnitByMeasuringSystem(measuringSystem: MeasuringSystem): Unit {
        switch(measuringSystem) {
            case MeasuringSystem.Metric:
                return this._units.find(x => x.metricDefault === true);
            case MeasuringSystem.US:
                return this._units.find(x => x.usDefault == true);
        }

    }
}

 function load() : Array<UnitGroup> {
    return UnitGroupsData.map((x: any) => {
        return new UnitGroup(x.Name, x.SignDigits, x.Offset, x.Unit.map((y: any) => {
            return new Unit(y.Name, y.Quantity, y.Multiplier, y.MeasuringSystem, y.DensityCalculation, y.SIUnit, y.MetricDefault, y.USDefault);
        }));
    });
}

class UnitPrecision {
  private readonly _name: string;
  private readonly _minValue: number;
  private readonly _maxValue: number;
  private readonly _incrementStep: number;

  constructor(name: string, minValue: number, maxValue: number, incrementStep: number) {
    this._name = name;
    this._minValue = minValue;
    this._maxValue = maxValue;
    this._incrementStep = incrementStep;
  }

  get name(): string {
    return this._name;
  }

  get minValue(): number {
    return this._minValue;
  }

  get maxValue(): number {
    return this._maxValue;
  }

  get incrementStep(): number {
    return this._incrementStep;
  }
}

class UnitGroupPrecision {
  private readonly _name: string;
  private readonly _unitPrecisions: Map<string, UnitPrecision>;

  constructor(name: string, unitPrecisions: Map<string, UnitPrecision>) {
    this._name = name;
    this._unitPrecisions = unitPrecisions;
  }

  get name(): string {
    return this._name;
  }

  get unitPrecisions(): Map<string, UnitPrecision> {
    return this._unitPrecisions;
  }
}

class UnitGroupPrecisions {
  private readonly _unitGroupPrecisions: Map<string, UnitGroupPrecision>;

  constructor(unitGroupPrecisions: Map<string, UnitGroupPrecision>) {
    this._unitGroupPrecisions = unitGroupPrecisions;
  }

  public getPrecision(unitGroupName: string, unitName: string): UnitPrecision {
    const x = this._unitGroupPrecisions.get(unitGroupName);
    return x.unitPrecisions.get(unitName);
  }
}

const xx = new Map<string, UnitGroupPrecision>();
for (let [unitGroupKey, unitGroupValues] of UnitGroupPrecisionsMap) {
  const yy = new Map<string, UnitPrecision>();
  for (let [unitKey, unitValues] of unitGroupValues) {
    const unitPrecision = new UnitPrecision(unitValues.name, unitValues.minValue, unitValues.maxValue, unitValues.increment);
    yy.set(unitKey, unitPrecision);
  }
  const tt = new UnitGroupPrecision(unitGroupKey, yy);
  xx.set(unitGroupKey, tt);
}

export const unitGroupPrecisions = new UnitGroupPrecisions(xx);

let unitGroupsCollection = load();

export enum MeasuringSystem {
    Metric = "Metric",
    US = "US"
}



export class UnitGroups {
    private static readonly unitGroupCollection: Array<UnitGroup> = load();

    public static getAll(): Array<UnitGroup> {
      return UnitGroups.unitGroupCollection;
    }

    public static getUnitGroup(name: string): UnitGroup {
      return UnitGroups.unitGroupCollection.find(x => x.name === name);
    }

    public static GetDefaultUnitForMeasuringSystem(unitGroupName: string, measuringSystem: MeasuringSystem): Unit {
        let unitGroup = unitGroupsCollection.find(x => x.name === unitGroupName);
        return unitGroup.getUnitByMeasuringSystem(measuringSystem);
    }

    public static Convert(unitGroupName: string, value: number, sourceUnit: string, targetUnit: string, densities?: Densities): number {
      if (sourceUnit !== targetUnit ){
        const unitGroup = UnitGroups.getUnitGroup(unitGroupName);
        let srcUnit = unitGroup.getUnit(sourceUnit);
        let dstUnit = unitGroup.getUnit(targetUnit);
        let srcSIValue = srcUnit.convertToSI(value, densities);
        return dstUnit.convertFromSI(srcSIValue, densities);
      } else {
        return value;
      }
    }
}






class Converter {
    public static CovnertFromSITo(value: number, targetUnit: string) {
      let unitGroup = UnitGroups.getAll().find(x => x.existsUnit(targetUnit));
      let unit = null;
      if (unitGroup) {
        unit = unitGroup.getUnit(targetUnit);
      }
        return unit.convertFromSI(value);
    }
}

interface ISSPValue {
    value: number;
    siValue: number;
    unit: string;
}

export class SSPValue {
    public value: number;
    public siValue: number;
    public unit: string;

    constructor(sspValue: ISSPValue) {
        this.value = sspValue.value;
        this.siValue = sspValue.siValue;
        this.unit = sspValue.unit;
    }

    public ConvertTo(targetUnit: string): SSPValue {
        let newValue = Converter.CovnertFromSITo(this.siValue, targetUnit);
        return new SSPValue({
            value: newValue,
            siValue: this.siValue,
            unit: targetUnit
        });
    }


}
