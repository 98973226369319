import initialState from './initialState';
import * as types from '../constants/actionTypes';

export default function loadItemsReducer(state = initialState.loadItems, action) {
    let newState;

    switch(action.type){
        case types.LOAD_FLUIDS_SP_SUCCESS: {
            let {fluids, ...ns} = state;
            newState = ns;
            let {singlePhase, ...exs} = fluids;
            newState.fluids = exs;
            singlePhase = action.value;
            newState.fluids.singlePhase = singlePhase;
            return newState;
        }
        case types.LOAD_SKETCHES: {
            let {...ns} = state;
            newState = ns;
            newState.sketches = action.value;
            return newState;
        }
        case types.ACCESSORIES_GROUPS_SUCCESS: {
            newState = {...state, accessoryGroups: action.accessoriesGroups };
            return newState;
        }
        case types.Constants.REDUX_LOADCOUNTRIES: {
            newState = {...state, countries: action.countries };
            return newState;
        }
        default:
            return state;
    }
}
