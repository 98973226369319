import React from 'react';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
      <div
        style={{
          position: 'relative',
          height: 'calc(100vh - 66px)'
        }}>
        <Outlet/>
      </div>
  );
};

export default React.memo(Layout);
