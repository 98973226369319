import React, { useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IconUserMenu } from 'components/Icons';
import LanguageSelect, {supportedApplicationLanguages, supportedPrintoutsLanguages} from 'components/common/LanguageSelect';
import UnitSystemSelect from 'components/common/UnitSystemSelect';
import { changeApplicationLanguage, changePrintoutLanguage, changeUnitSystem } from 'actions/userProfileActions';
import get from 'lodash/get';
import DTPopover from "../../Popover";
import useUserProfile from "../../common/hooks/useUserProfile";
import {logout} from '../../../containers/Account/actions';
import {useTranslation} from 'react-i18next';

const UserMenu = () => {
  const dispatch = useDispatch();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userProfile = useUserProfile();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const handleApplicationLanguageChange = (languageCode) => {
    dispatch(changeApplicationLanguage(languageCode));
  };

  const handlePrintoutLanguageChange = (languageCode) => {
    dispatch(changePrintoutLanguage(languageCode));
  };

  const handleUnitSystemChange = (unitSystem) => {
    dispatch(changeUnitSystem(unitSystem));
  };

  const onTogglePopover = (currentTarget) => {
    setAnchorEl(currentTarget);
    setPopoverOpen(!popoverOpen);
  };

  const onLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const workingLanguage = (get(userProfile, 'settings.workingLanguage') || 'en').toUpperCase();
  const printoutLanguage = (get(userProfile, 'settings.printouts.languagePrimary') || 'en').toUpperCase();

  return (
    <div>
      <div className="p-2 me-3 user-menu" id="u-menu" onClick={(e) => onTogglePopover(e.currentTarget)}>
        <div className="d-inline w-100">
          <IconUserMenu size={32} />
          <span className="align-middle" style={{ fontSize:"14px"}}>{userProfile.email}</span>
        </div>
        {userProfile.packages && userProfile.packages.includes('Demo') && (
          <div className="w-100 align-middle text-center demo-style" style={{ fontSize:"14px"}}>DEMO</div>
        )}
      </div>
      <DTPopover anchorElement={anchorEl}
                 isOpen={popoverOpen}
                 toggle={() => onTogglePopover(null)}
      >
        <div className="card">
          <div className="card-header">
            <div className="d-flex">
              <div className="p-2" >
                <IconUserMenu size={50} className="" />
              </div>
              <div style={{fontSize:"14px"}} className="d-inline p-2" >
              <span className="align-middle">
                  {userProfile.firstName} {userProfile.lastName}<br/>
                {userProfile.email} <br/>
                <NavLink to="/settings">
                  {t('Settings')}
                </NavLink>
              </span>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="d-flex user-menu-item">
              {t('Application', 'Application')}
              <div className="ms-auto">
                <LanguageSelect
                  languages={supportedApplicationLanguages}
                  value={workingLanguage}
                  onChange={handleApplicationLanguageChange}/>
              </div>
            </div>
            <hr/>
            <div className="d-flex user-menu-item">
              {t('Printout', 'Printout')}
              <div className="ms-auto">
                <LanguageSelect
                  languages={supportedPrintoutsLanguages}
                  value={printoutLanguage}
                  onChange={handlePrintoutLanguageChange}/>
              </div>
            </div>
            <hr/>
            <div className="d-flex user-menu-item">
              {t('Units', 'Units')}
              <div className="ms-auto">
                <UnitSystemSelect selected={userProfile.settings.units} onChange={handleUnitSystemChange}/>
              </div>
            </div>
            <hr/>
            <div className="d-flex user-menu-item">
              {t('AboutLicense', 'License')}
              <div className="ms-auto w-75 flex-wrap">
                {userProfile.packages && userProfile.packages.join(', ')}
              </div>
            </div>
            <hr/>
            <div className="d-flex justify-content-center user-menu-item">
              <button type="button" className="btn btn-link" onClick={onLogout}>
                {t('SignOut', 'Sign out')}
              </button>
            </div>
          </div>
        </div>
      </DTPopover>
    </div>
  );
};

UserMenu.propTypes = {
};

export default React.memo(UserMenu);
