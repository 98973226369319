import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {compose} from 'redux';
import withAuthStyle from './withAuthStyle';
import {RouterPaths} from '../App/RouterPaths';
import LoadingIndicator from 'components/LoadingIndicator';
import TextBox from "components/common/TextBox";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../../applicationInsights";
import useTrackPageVisit from "../../components/common/hooks/useTrackPageVisit";
import {Link, Navigate, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {login} from './actions';
import {makeSelectIsAuthenticated, makeSelectLogin} from './selectors';
import get from 'lodash/get';
import {useInjectReducer} from '../../utils/injectReducer';
import reducer, {ReducerKey} from './reducer';
import {useInjectSaga} from '../../utils/injectSaga';
import saga, {SagaKey} from './saga';

const LoginPage = () => {
  useTrackPageVisit("Login");
  useInjectReducer({ key: ReducerKey, reducer: reducer });
  useInjectSaga({ key: SagaKey, saga: saga });
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const {loading, error} = useSelector(makeSelectLogin());
  const isAuthenticated = useSelector(makeSelectIsAuthenticated());


  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const isInputValid = (val) => {
    if (val === null) return true;
    if (val.length === 0) return false;
    return true;
  };

  const isFormValid = () => {
    return username && username.length > 0
      && password && password.length > 0;
  };

  const handleLogin = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      dispatch(login(username, password));
    }
  };

  if (isAuthenticated) {
    const pathname = get(location, "state.from.pathname", RouterPaths.HOME);
    const search = get(location, "state.from.search", '');
    return <Navigate to={`${pathname}${search}`} replace/>;
  }

  return (
    <form id="login-form" name="login-form" onSubmit={(e) => handleLogin(e)}>
      <div className="col-md-10 col-md-offset-6">
        <span className="title">Register or Sign in</span>
        <div className="login-input-group py-3 pt-5">
          <TextBox error={!isInputValid(username)}
                   variant="standard"
                   label="Username"
                   id="login-username"
                   name="login-username"
                   className="auth-inputs"
                   defaultValue={username || ''}
                   onChange={(e) => setUsername(e.target.value)}
                   autoComplete="username"/>
        </div>
        <div className="login-input-group py-3">
          <TextBox error={!isInputValid(password)}
                   variant="standard"
                   id="login-password"
                   name="login-password"
                   label="Password"
                   type="password"
                   className="auth-inputs"
                   defaultValue={password || ''}
                   onChange={e => setPassword(e.target.value)}
                   autoComplete="current-password"/>
        </div>
        <div className="pt-4 pb-3 row">
          <div className="col-3">
            <input type="submit" id="login-btn-submit" name="login-btn-submit"
                   className="btn btn-light login-btn float-start" required={true} value="Login"/>
          </div>
          {loading && <div className="col-2 align-middle p-2">
            <LoadingIndicator/>
          </div>}
        </div>
        {error && <div className="alert alert-danger" role="alert">{error.detail}</div>}
        <div className="auth-actions">
          <Link to={RouterPaths.FORGOTTENPASSWORD}
                className="btn btn-link">{t('LoginPage.ForgottenPassword.Link', 'Forgotten password')}</Link>
          <Link to={RouterPaths.REGISTER} className="btn btn-link">{t('LoginPage.Register.Link', 'Register')}</Link>
        </div>
      </div>
    </form>
  );
};

export default compose(
  withAuthStyle
)(withAITracking(reactPlugin, LoginPage, "Login"));
