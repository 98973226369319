import {
  SinglePhase,
  SinglePhaseDual,
  Condenser,
  CondenserDual,
  CondenserHeatPump,
  Evaporator,
  EvaporatorDual,
  EvaporatorHeatPump,
  LiquidEvaporator,
  Cascade,
  TwoStage,
  AirDryer,
  DistrictEnergy,
  AHRI,
  Hypertwain
} from 'constants/calculationTypes';
import {generatePath} from 'react-router-dom';

export class RouterPaths {
  static HOME = '/';
  static CALCULATIONS = '/calculations/:saved?/calculationType/:calculationType/uuid/:uuid';
  static CALCULATION_RESULT = 'results';
  static CALCULATION_RESULT_DETAILS = 'result/:resultId/:subResultId?';
  static SINGLEPHASE= `/${SinglePhase}/:calculation/:calcId`;
  static SINGLEPHASEDUAL= `/${SinglePhaseDual}/:calculation/:calcId`;
  static EVAPORATOR= `/${Evaporator}/:calculation/:calcId`;
  static EVAPORATORDUAL= `/${EvaporatorDual}/:calculation/:calcId`;
  static EVAPORATOR_HEAT_PUMP= `/${EvaporatorHeatPump}/:calculation/:calcId`;
  static LIQUIDEVAPORATOR= `/${LiquidEvaporator}/:calculation/:calcId`;
  static CONDENSER= `/${Condenser}/:calculation/:calcId`;
  static CONDENSERDUAL= `/${CondenserDual}/:calculation/:calcId`;
  static CONDENSER_HEAT_PUMP= `/${CondenserHeatPump}/:calculation/:calcId`;
  static CASCADE= `/${Cascade}/:calculation/:calcId`;
  static TWOSTAGE= `/${TwoStage}/:calculation/:calcId`;
  static AIRDRYER= `/${AirDryer}/:calculation/:calcId`;
  static DISTRICTENERGY= `/${DistrictEnergy}/:calculation/:calcId`;
  static AHRI= `/${AHRI}/:calculation/:calcId`;
  static HYPERTWAIN= `/${Hypertwain}/:calculation/:calcId`;
  static SAVEDCALCULATIONS = '/savedCalculations';
  static PRODUCTBASKET= "/productbasket";
  static PRODUCTSELECTOR= "/productSelector/calculation/:uuid";
  static PRODUCTSELECTOR_ARTICLES= "articles";
  static PRODUCTSELECTOR_ARTICLES_SELECTED_ARTICLE= "article/:article";
  static PRODUCTSELECTOR_XPCS= "xpcs";
  static PRODUCTSELECTOR_XPCS_SELECTED_XPC= "xpc/:xpc";
  static CONNECTIONIMPACT= "/connectionImpact";
  static SETTINGS = "/settings";
  static FLUIDPROPERTIES= "/fluidProperties";
  static FLUID_EDITOR = "/fluidEditor";
  static PRESSURE_DROP_TOOL = "/pressureDrop";
  static CIP_TOOL = "/cipTool";
  static ABOUT= "/about";
  static LOGIN= "/login";
  static LOGIN_CX_PORTAL = "/login/cx-portal";
  static LOGIN_CX_PORTAL_CALLBACK = "/login/cx-portal/callback";
  static LOGOUT_CX_PORTAL = '/logout/cx-portal';
  static FORGOTTENPASSWORD= "/forgottenpassword";
  static RESET_PASSWORD= "/ResetPassword";
  static REGISTER= "/register";
  static VERIFY= "/verify";
  static CONFIRM_EMAIL = '/confirmEmail/:userId/:code';
  static XREFERENCE= "/xreference";
  static MULTICALC= "/multicalc";
  static MULTICALCSAVEDCALCULATIONS= "/multicalc-savedcalculations";
  static EASYSELECT= "/easyselect";
  static EASYSELECT_TAPWATER= "/easyselect-tapwater";
  static EASYSELECT_HEATING= "/easyselect-heating";
}

export function generateCalculationPath({ calculationType, uuid, saved }) {
  return generatePath(RouterPaths.CALCULATIONS, {
    calculationType,
    uuid,
    saved
  });
}

export function generateCalculationPathWithResult({ calculationType, uuid, saved }) {
  return generatePath(RouterPaths.CALCULATIONS+'/'+RouterPaths.CALCULATION_RESULT, {
    calculationType,
    uuid,
    saved
  });
}

export function generateCalculationPathWithSelectedResult({ calculationType, uuid, saved, resultId, subResultId }) {
  return generatePath(RouterPaths.CALCULATIONS+'/'+RouterPaths.CALCULATION_RESULT+'/'+RouterPaths.CALCULATION_RESULT_DETAILS, {
    calculationType,
    uuid,
    saved,
    resultId,
    subResultId
  });
}

export function generateProductSelectorArticlesPath({ uuid, queryParams }) {
  const basePath = generatePath(RouterPaths.PRODUCTSELECTOR+'/'+RouterPaths.PRODUCTSELECTOR_ARTICLES, {
    uuid
  });

  return queryParams ? `${basePath}?${queryParams}` : basePath;
}

export function generateProductSelectorXPXCPath({ uuid, queryParams }) {
  const basePath = generatePath(RouterPaths.PRODUCTSELECTOR+'/'+RouterPaths.PRODUCTSELECTOR_XPCS, {
    uuid
  });

  return queryParams ? `${basePath}?${queryParams}` : basePath;
}

export function generateProductSelectorSelectedArticlePath({ uuid, article, queryParams }) {
  const basePath = generatePath(RouterPaths.PRODUCTSELECTOR+'/'+RouterPaths.PRODUCTSELECTOR_ARTICLES+'/'+RouterPaths.PRODUCTSELECTOR_ARTICLES_SELECTED_ARTICLE, {
    uuid,
    article
  });

  return queryParams ? `${basePath}?${queryParams}` : basePath;
}

export function generateProductSelectorSelectedXPCPath({ uuid, xpc, queryParams }) {
  const basePath = generatePath(RouterPaths.PRODUCTSELECTOR+'/'+RouterPaths.PRODUCTSELECTOR_XPCS+'/'+RouterPaths.PRODUCTSELECTOR_XPCS_SELECTED_XPC, {
    uuid,
    xpc
  });

  return queryParams ? `${basePath}?${queryParams}` : basePath;
}
