import React from 'react';
import PropTypes from 'prop-types';
import TechnicalDataUni from '../TechnicalData/Loadable';
import Totals from '../Totals/Loadable';
import DimensionalData from '../Dimensionals/Loadable';
import CalculationResultCarbonFootprint from '../CarbonFootprint/CalculationResultCarbonFootprint/Loadable';
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabsWrapper from "../common/tabs/TabsWrapper";
import Messages from "../common/messages/Messages";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {Stack} from '@mui/material';


const CalculationResultDetail = ({data, inputs, calculationType, getProductNumber, workingLanguage}) => {
    const [value, setValue] = React.useState(0);
    const { t } = useTranslation();

    if(!data){
        return null;
    }

    function handleChange(event, newValue) {
      setValue(newValue);
    }

    function a11yProps(index) {
        return {
            id: `scrollable-force-tab-${index}`,
            'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }

    const goToProductSite = () => window.open(`https://www.swep.net/products/${data.productSheetName}`, "_blank", "noreferrer");
    const getProductSheet = () => {
        getProductNumber(data.xpcString, (productNumber) => window.open(`https://sweppim.azurewebsites.net/pdf/GetProductSheet?productnumber=${productNumber}&languageCode=${workingLanguage||'en'}`, "_blank", "noreferrer"));
    };

    return (
      <Paper elevation={2} className="p-3 mb-3">
        <div className="result-tbl-content calc-result-data">
          <div className="row result-top">
            <div className="col-6">
              <p className="h3">{t("HeatExchanger")} {data.bphe}</p>
              {/*<p className="h6">{data.extraLabels.side1Name}: {data.extraLabels.side1Value}</p>*/}
              {/*<p className="h6">{data.extraLabels.side2Name}: {data.extraLabels.side2Value}</p>*/}
            </div>
            <div className="col-6">
              <Stack container spacing={3} direction="row" justifyContent="flex-end" alignItems="flex-end">
                <Button color="primary" variant="contained" size="small" onClick={goToProductSite}>{t('ProductSelector.SelectedItem.ProductSiteButton.Text', 'Product site')}</Button>
                <Button color="primary" variant="contained" size="small" onClick={getProductSheet}>{t('ProductSelector.SelectedItem.ProductSheetButton.Text', 'Product sheet')}</Button>
              </Stack>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Messages messages={data.messages} />
            </div>
          </div><hr/>
          <div className="row-col-12">
            <Tabs
              className="pb-3"
              value={value}
              onChange={handleChange}
              variant="standard"
              scrollButtons
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
              allowScrollButtonsMobile>
              <Tab label={t('ProductSelector.SelectedItem.TechnicalDataTabTitle', 'Technical Data')} {...a11yProps(0)} />
              <Tab label={t('ProductSelector.SelectedItem.DimensionalDataTabTitle', 'Dimensional Info')} {...a11yProps(1)} />
              <Tab label={t('ProductSelector.SelectedItem.TotalsTabTitle', 'Totals')} {...a11yProps(2)} />
              <Tab label={t('ProductSelector.SelectedItem.CarbonFootprintTabTitle', 'Carbon footprint')} {...a11yProps(3)} />
            </Tabs>
            <TabsWrapper value={value} index={0} >
              {value === 0 && <TechnicalDataUni data={data} inputs={inputs} calculationType={calculationType} />}
            </TabsWrapper>
            <TabsWrapper value={value} index={1}>
              {value === 1 && <DimensionalData data={data} />}
            </TabsWrapper>
            <TabsWrapper value={value} index={2}>
              {value === 2 && <Totals data={data.totalsInfo} calculation={calculationType} />}
            </TabsWrapper>
            <TabsWrapper value={value} index={3}>
              {value === 3 && <CalculationResultCarbonFootprint calculationResult={data}/>}
            </TabsWrapper>
          </div>
        </div>
      </Paper>
    );
};

CalculationResultDetail.propTypes = {
  data: PropTypes.object,
  inputs: PropTypes.object,
  calculationType: PropTypes.string,
  getProductNumber: PropTypes.func.isRequired,
  workingLanguage: PropTypes.string.isRequired,
};

export default React.memo(CalculationResultDetail);
