import React from 'react';
import PropTypes from 'prop-types';
import {Popover} from "@mui/material";

const DTPopover = (props) => {
  const id = open ? 'dthermx-popover' : undefined;

  const handleClose = () => {
    // setAnchorEl(null);
    props.toggle();
  };

  // console.log('DTPopover', props);
  return (
    <div>
      <Popover
        id={id}
        open={props.isOpen}
        anchorEl={props.anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {props.children}
      </Popover>
    </div>
  );
};

DTPopover.propTypes = {
  anchorElement: PropTypes.element,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.object,
};

export default DTPopover;
