import { initialState} from './reducer';

const accountState = state => state.account || initialState;

const makeSelectLogin = () => state => {
  return accountState(state).login;
};

const makeSelectResetPassword = () => state => {
  return accountState(state).resetPassword;
};

const makeSelectConfirmEmail = () => state => {
  return accountState(state).confirmEmail;
};

const makeSelectAuthenticateStatus = () => state => {
  return accountState(state).authenticateStatus;
};

const makeSelectIsAuthenticated = () => state => {
  return accountState(state).authenticateStatus.isAuthenticated;
};

export {
  makeSelectLogin,
  makeSelectResetPassword,
  makeSelectConfirmEmail,
  makeSelectAuthenticateStatus,
  makeSelectIsAuthenticated
};
