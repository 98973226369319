import {dthermXBaseAddress} from '../api/baseAddresses';

let todoList = [];

setInterval(() => {
  if (todoList.length > 0) {
    fetch(`${dthermXBaseAddress()}/i18n/translations/usage/save`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        translationUsages: todoList
      })
    });
    todoList = [];
  }
}, 5000);


const I18nKeyUsagePostProcessor = {
  type: 'postProcessor',
  name: 'I18nKeyUsagePostProcessor',

  process: (value, key, options, translator) => {

    const isTest = process.env.NODE_ENV !== 'production';
    if (isTest) {
      const payload = {
        languageCode: translator.language,
        namespaceName: options.ns,
        keys: key,
        value: value
      };
      todoList.push(payload);
    }

    return value;
  }
};


export default I18nKeyUsagePostProcessor;
