import {createSelector} from 'reselect';
import get from 'lodash/get';
import initialState from '../reducers/initialState';

const selectUserProfile = state => state.userProfile || initialState.userProfile;
const makeSelectUserProfile = () => selectUserProfile;
const selectUserProfileUnits = () => state => selectUserProfile(state).settings.units;
const selectWorkingLanguage = () => state => selectUserProfile(state).settings.workingLanguage;
const selectIsTranslationAdmin = () => state => selectUserProfile(state).isTranslationAdmin;
const makeSelectUserId = () => state => selectUserProfile(state).userId || null;
const makeSelectIsExportToCRMEnabled = () => state => selectUserProfile(state).isExportToCMREnabled || null;
const makeSelectUserAllowedCalculations = () => state => selectUserProfile(state).allowedCalculationTypes;
const makeSelectAllowedPrintoutFormats = () => state => selectUserProfile(state).allowedPrintoutFormats;
const makeSelectTenderPrintoutEnabled = () => state => selectUserProfile(state).isTenderPrintoutEnabled || null;
const makeSelectUserProfileFeatures = () => state => selectUserProfile(state).features || [];
const makeSelectUserProfilePackages = () => state => selectUserProfile(state).packages || [];
const makeSelectFilters = (calculationType) =>
  createSelector(
    selectUserProfile,
    userProfileState => {
      const calculationTypeFilterSetting = get(userProfileState, 'settings.filterSettings.calculationTypeFilterSettings', []).find(x => x.calculationType === calculationType);
      if (calculationTypeFilterSetting) return calculationTypeFilterSetting.calculationFilterSettingsData;

      const defaultCalculationFilterSettings = get(userProfileState, 'settings.filterSettings.defaultCalculationFilterSettings', null);
      if (defaultCalculationFilterSettings) return defaultCalculationFilterSettings;

      return null;
    }
  );

const makeSelectSettings = () => state => selectUserProfile(state).settings;

const makeSelectAllowTelemetry = () => state => selectUserProfile(state).settings.allowTelemetry || null;


export {
  makeSelectUserProfile,
  makeSelectUserId,
  makeSelectIsExportToCRMEnabled,
  makeSelectUserAllowedCalculations,
  makeSelectAllowedPrintoutFormats,
  makeSelectTenderPrintoutEnabled,
  makeSelectFilters,
  makeSelectUserProfileFeatures,
  makeSelectUserProfilePackages,
  selectUserProfileUnits,
  selectWorkingLanguage,
  selectIsTranslationAdmin,
  makeSelectSettings,
  makeSelectAllowTelemetry
};
