import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import NavBar from '../../components/header/NavBar';
import SideNavBar from '../../components/sideNav/SideNavBar';
import { makeSelectUserAllowedCalculations } from '../../selectors/userProfileSelectors';
import DemoLicenseBanner from '../../components/DemoLicenseBanner';
import {Box} from '@mui/material';

const PrivateLayout = () => {
  const allowedCalculations = useSelector(makeSelectUserAllowedCalculations());

  return (
    <>
      <NavBar/>
      <div
        style={{
          position: 'relative',
          height: 'calc(100vh - 66px)'
        }}>
        <SideNavBar allowedCalculations={allowedCalculations}>
          <Box sx={{ pb: 3 }}>
            <DemoLicenseBanner/>
          </Box>
          <Outlet />
        </SideNavBar>
      </div>
    </>
  );
};

PrivateLayout.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'PrivateLayout',
  trackHooks: true
};

export default React.memo(PrivateLayout);

