import React, { useEffect, memo, useCallback } from 'react';
import {Outlet, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import saga from './saga';
import { makeSelectCalculation } from './selector';
import {
  changeCalculationInputs,
  setCalculationValidity,
  reloadCalculationHeatExchangers,
  changeSelectedCalculationFilters,
  calculate, loadCalculation, loadCalculationXref, cloneCalculation
} from './actions';
import injectSaga from '../../utils/injectSaga';
import {uuidv4} from '../../utils/uuid';
import CalculationInputsNEW from './CalculationInputsNEW';
import LoadingPanel from '../../components/LoadingPanel';
import ErrorPanel from '../../components/common/ErrorPanel/ErrorPanel';
import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CalculationFilters from './CalculationFiltersNew';
import Grid from '@mui/material/Grid';
import CalculateButton from './Components/CalculateButton';
import CalculationHeader from './Components/CalculationHeader';
import {compose} from 'redux';
import {generateCalculationPath, generateCalculationPathWithResult} from '../App/RouterPaths';

const InputsPaper = styled(Paper)(({ theme }) => ({
  elevation: 2,
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(3),
  marginRight: theme.spacing(2),
}));

const key = "newCalculations";

const CalculationsBootstrapper = () => {
  const dispatch = useDispatch();
  const { calculationType, uuid, saved } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (uuid === "new") {
      const newUuid = uuidv4();
      navigate(generateCalculationPath({ calculationType: calculationType, uuid: newUuid }));
    } else if (uuid === 'clone') {
      const sourceUuid = searchParams.get("uuid");
      dispatch(cloneCalculation(sourceUuid, ({ calculationType, uuid }) => {
        navigate(generateCalculationPath({ calculationType: calculationType, uuid: uuid }));
      }));
    } else if (uuid === "xref") {
      const categories = searchParams.getAll("heName");
      dispatch(loadCalculationXref(categories, ({ calculationType, uuid }) =>{
        navigate(generateCalculationPath({ calculationType: calculationType, uuid: uuid }));
      }));
    } else {
      dispatch(loadCalculation(uuid, calculationType, saved));
    }
  }, [calculationType, dispatch, navigate, saved, searchParams, uuid]);

  const calculationData = useSelector(makeSelectCalculation(uuid));

  const handleChangedInputs = useCallback((values) => {
    dispatch(changeCalculationInputs(uuid, values));
  }, [dispatch, uuid]);

  const handleSetInputsValidity = useCallback((isValid) => {
    dispatch(setCalculationValidity(uuid, isValid));
  }, [dispatch, uuid]);

  const handleReloadHeatExchangers = useCallback((modelRestrictions, coCurrent, selectedFluidSide1, selectedFluidSide2) => {
    dispatch(reloadCalculationHeatExchangers(uuid, modelRestrictions, coCurrent, selectedFluidSide1, selectedFluidSide2));
  }, [dispatch, uuid]);

  const handleFilterChange = useCallback((filterName, selection) => {
    dispatch(changeSelectedCalculationFilters(uuid, filterName, selection));
  }, [dispatch, uuid]);

  const handleCalculate = useCallback(() => {
    dispatch(calculate(uuid));
    navigate(generateCalculationPathWithResult({
      calculationType: calculationType,
      uuid: uuid
    }));
  }, [calculationType, dispatch, navigate, uuid]);

  if (!calculationData) return null;
  // if (!calculationData.inputParams) return null;
  return (
      <div>
        <CalculationHeader calculationType={calculationType}/>
        <LoadingPanel isLoading={calculationData.loading}>
          <ErrorPanel error={calculationData.error}>
            {calculationData.inputParams &&
              <Grid container>
                <Grid item xs={12} md={12} lg={5} xl={5}>
                  <InputsPaper>
                    <CalculationInputsNEW
                      calculationData={calculationData.inputParams}
                      onChangeInputs={handleChangedInputs}
                      onSetInputsValidity={handleSetInputsValidity}
                      loadCalculationInputsHeatExchangers={handleReloadHeatExchangers}/>
                    <CalculationFilters
                      calculationType={calculationData.inputParams.userCalculationInputs.calculationType.toLowerCase()}
                      selectedFilters={calculationData.inputParams.filterSettings}
                      onSelectedFiltersChange={handleFilterChange}/>
                    <CalculateButton disabled={calculationData.isValid} onCalculate={handleCalculate}/>
                  </InputsPaper>
                </Grid>
                <Grid item xs={12} md={12} lg={7} xl={7}>
                  <LoadingPanel isLoading={calculationData.isCalculating}>
                    <ErrorPanel error={calculationData.calculationError}>
                      {/*<CalculationResults uuid={uuid}/>*/}
                      <Outlet/>
                    </ErrorPanel>
                  </LoadingPanel>
                </Grid>
              </Grid>
            }
          </ErrorPanel>
        </LoadingPanel>
      </div>
  );
};

export default compose(
  //injectReducer({ key, reducer, isPersistent: true }),
  injectSaga({ key, saga }),
  memo,
  )(CalculationsBootstrapper);
// export default memo(CalculationsBootstrapper);
