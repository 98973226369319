import {initialState} from '../reducers/externalSourcesReducer';

const selectExternalSources = state => state.externalSources || initialState;
const makeSelectCaseStories = () => state => selectExternalSources(state).caseStories;
const makeSelectCarousel = () => state => selectExternalSources(state).carousel;
const makeSelectWikiMessages = () => state => selectExternalSources(state).wiki;

export {
  makeSelectCaseStories,
  makeSelectWikiMessages,
  makeSelectCarousel
};
