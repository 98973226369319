import * as types from '../constants/actionTypes';
import userProfileApi from '../api/userProfileApi';
import {LOAD_USER_PROFILE_DATA} from '../constants/actionTypes';

export function loadUserProfileData() {
  return {
    type: LOAD_USER_PROFILE_DATA
  };
}

export function loadUserProfileDataSuccess(userProfileData) {
    return {
        type: types.LOAD_USER_PROFILE_DATA_SUCCESS,
        userProfileData : mapUserProfileData(userProfileData)
    };
}

function mapUserProfileData(userProfileData) {
    let {userProfile} = userProfileData;

    let profile = {};
    profile.email = userProfile.email;
    profile.userId = userProfile.userId;
    profile.firstName = userProfile.firstName;
    profile.lastName = userProfile.lastName;
    // profile.company = userProfile.company;
    // profile.address = userProfile.address;
    // profile.country = userProfile.country;
    // profile.phone = userProfile.phone;
    profile.settings = userProfile.settings;
    profile.allowedCalculationTypes = userProfile.allowedCalculationTypes;
    profile.allowedPrintoutFormats = userProfile.allowedPrintoutFormats;
    profile.isTranslationAdmin = userProfile.isTranslationAdmin;
    profile.isECommerceUser = userProfile.isECommerceUser;
    profile.isTelemetryEnabled = userProfile.isTelemetryEnabled;
    profile.isNoTelemetryEnabled = userProfile.isNoTelemetryEnabled;
    profile.isExportToCMREnabled = userProfile.isExportToCMREnabled;
    profile.isTenderPrintoutEnabled = userProfile.isTenderPrintoutEnabled;
    profile.isProductBasketEnabled = userProfile.isProductBasketEnabled;
    profile.isSavingCalculationsEnabled = userProfile.isSavingCalculationsEnabled;
    profile.packages = userProfile.packages;
    profile.features = userProfile.features;

    return profile;
}

export function loadUserProfileDataError(error) {
    return {
        type: types.LOAD_USER_PROFILE_DATA_ERROR,
        error: error
    };
}

export function saveUserProfileData(userProfileData) {
    return (dispatch) => {
        return userProfileApi.saveUserProfileData(userProfileData).then((response) => {
            dispatch(saveUserProfileDataSuccess(response.data));
        }).catch((error) => {
            dispatch(saveUserProfileDataError(error.message));
        });
    };
}

export function saveUserProfileDataSuccess(userProfileData) {
    return {
        type: types.SAVE_USER_PROFILE_DATA_SUCCESS,
        userProfileData
    };
}

export function saveUserProfileDataError(message) {
    return {
        type: types.SAVE_USER_PROFILE_DATA_ERROR,
        message: message
    };
}

export function changeApplicationLanguage(language) {
    return {
        type: types.APPLICATION_LANGUAGE_CHANGE,
        language: language
    };
}

export function changePrintoutLanguage(language) {
    return {
        type: types.PRINTOUT_LANGUAGE_CHANGE,
        language: language
    };
}

export function changeUnitSystem(unitSystem) {
    return {
        type: types.UNIT_SYSTEM_LANGUAGE_CHANGE,
        unitSystem: unitSystem
    };
}

export function changeShowWelcomeDialog(showWelcomeDialog) {
  return {
    type: types.SHOW_WELCOME_DIALOG_CHANGE,
    showWelcomeDialog: showWelcomeDialog
  };
}

export function changeAllowTelemetry(allowTelemetry) {
  return {
    type: types.ALLOW_TELEMETRY,
    allowTelemetry: allowTelemetry
  };
}
