import React from 'react';
import PropTypes from 'prop-types';
import {IconButton} from "@mui/material";
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import Link from '@mui/material/Link';

const CloneCalculationButton = ({ tabIndex, path }) => {

  return (
    <IconButton
      aria-describedby={"filters-actions"}
      tabIndex={tabIndex}
      title="Clone calculation"
      size="large">
      <Link  href={path} color="textSecondary" target="_blank">
        <FilterNoneIcon/>
      </Link>
    </IconButton>
  );
};

CloneCalculationButton.propTypes = {
  path: PropTypes.string,
  tabIndex: PropTypes.number,
};

export default CloneCalculationButton;
