import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import XHR from 'i18next-xhr-backend'; // fallback xhr load
import I18nKeyUsagePostProcessor from "./utils/I18nKeyUsagePostProcessor";
import {dthermXBaseAddress} from './api/baseAddresses';
// import I18nTranslationAdminPostProcessor from "./utils/I18nTranslationAdminPostProcessor";

// eslint-disable-next-line import/no-named-as-default-member
i18n//.use(LanguageDetector)
  .use(ChainedBackend)
  .use(initReactI18next)
  // .use(I18nKeyUsagePostProcessor)
  .use(I18nKeyUsagePostProcessor)
  //.use(I18nTranslationAdminPostProcessor)
  .init({
    default: 'en',
    fallbackLng: (code) => {
      if (code) {
        const languageCode = code.substring(0, 2);
        return [languageCode, 'en'];
      }
      return ['en'];
    },
    saveMissing: process.env.NODE_ENV !== 'production',
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations", "printouts", "fluids"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
      lookupQuerystring: 'locale',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',
      // cache user language
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
      //cookieMinutes: 10,
      //cookieDomain: 'myDomain'
      convertDetectedLanguage: (lng) => lng.substring(0, 2)
    },
    react: {
      useSuspense: true
    },
    backend: {
      backends: [
        LocalStorageBackend,  // primary
        XHR                   // fallback
      ],
      backendOptions: [{
        prefix: 'i18next_res_',

        // expiration
        // expirationTime: 7*24*60*60*1000,
        expirationTime: 24*60*60*1000,
        // language versions
        versions: {}
        },{
        // for all available options read the backend's repository readme file
        // loadPath: '/resources/translations/{{lng}}/{{ns}}.json',
        loadPath: `https://dthermx-files.swep.net/${process.env.blobStorageTranslationsContainerName}/translations/{{lng}}/{{ns}}.json`,
        // path to post missing resources
        addPath: `${dthermXBaseAddress()}/i18n/keys/add/{{lng}}/{{ns}}`,
      }]
    },
    postProcess: ['I18nKeyUsagePostProcessor', 'I18nTranslationAdminPostProcessor']
});

export default i18n;
