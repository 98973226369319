import React, {useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {makeSelectCalculationInputs, makeSelectSelectedResult} from '../selector';

import CalculationResultData from '../../../components/calculation/result/Calculation/CalculationResultData';
import {downloadProductSheet} from '../actions';
import {selectWorkingLanguage} from '../../../selectors/userProfileSelectors';

const CalculationResultsDetails = () => {
  const { calculationType, uuid, resultId, subResultId } = useParams();
  const dispatch = useDispatch();

  const inputs = useSelector(makeSelectCalculationInputs(uuid));
  const selectedExchanger = useSelector(makeSelectSelectedResult(uuid));
  const workingLanguage = useSelector(selectWorkingLanguage());

  const handleGetProductNumber = useCallback((xpc) => {
    dispatch(downloadProductSheet(xpc));
  }, [dispatch]);

  return (
    <CalculationResultData
      calculationType={calculationType}
      inputs={inputs}
      selectedExchanger={selectedExchanger}
      getProductNumber={handleGetProductNumber}
      workingLanguage={workingLanguage}/>
  );
};
export default React.memo(CalculationResultsDetails);
