import {
  ACCOUNT_CONFIRM_EMAIL,
  ACCOUNT_CONFIRM_EMAIL_ERROR,
  ACCOUNT_CONFIRM_EMAIL_SUCCESS,
  ACCOUNT_FORGOTTEN_PASSWORD,
  ACCOUNT_FORGOTTEN_PASSWORD_ERROR,
  ACCOUNT_FORGOTTEN_PASSWORD_SUCCESS,
  ACCOUNT_REGISTER,
  ACCOUNT_REGISTER_ERROR,
  ACCOUNT_REGISTER_SUCCESS,
  ACCOUNT_RESET_PASSWORD,
  ACCOUNT_RESET_PASSWORD_ERROR,
  ACCOUNT_RESET_PASSWORD_SUCCESS,
  AUTHENTICATE_STATUS,
  AUTHENTICATE_STATUS_ERROR,
  AUTHENTICATE_STATUS_SUCCESS,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
} from './contants';

export function login(username, password) {
  return {
    type: LOGIN,
    username: username,
    password: password
  };
}

export function loginSuccess() {
  return {
    type: LOGIN_SUCCESS
  };
}

export function loginError(error) {
  return {
    type: LOGIN_ERROR,
    error: error
  };
}


export function logout() {
  return {
    type: LOGOUT
  };
}

export function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS
  };
}

export function logoutError(error) {
  return {
    type: LOGOUT_ERROR,
    error: error
  };
}

export function authenticateStatus() {
  return {
    type: AUTHENTICATE_STATUS
  };
}

export function authenticateStatusSuccess() {
  return {
    type: AUTHENTICATE_STATUS_SUCCESS
  };
}

export function authenticateStatusError(error) {
  return {
    type: AUTHENTICATE_STATUS_ERROR,
    error: error
  };
}

export function resetPassword(email, resetCode, newPassword) {
  return {
    type: ACCOUNT_RESET_PASSWORD,
    email,
    resetCode,
    newPassword
  };
}

export function resetPasswordSuccess() {
  return {
    type: ACCOUNT_RESET_PASSWORD_SUCCESS
  };
}

export function resetPasswordError(error) {
  return {
    type: ACCOUNT_RESET_PASSWORD_ERROR,
    error
  };
}

export const forgottenPassword = (email, successCallback, errorCallback) => {
  return {
    type: ACCOUNT_FORGOTTEN_PASSWORD,
    email,
    successCallback,
    errorCallback
  };
};

export const forgottenPasswordSuccess = () => {
  return {
    type: ACCOUNT_FORGOTTEN_PASSWORD_SUCCESS
  };
};

export const forgottenPasswordError = (error) => {
  return {
    type: ACCOUNT_FORGOTTEN_PASSWORD_ERROR,
    error
  };
};

export function register(registerData, successCallback, errorCallback) {
  return {
    type: ACCOUNT_REGISTER,
    registerData: registerData,
    successCallback: successCallback,
    errorCallback: errorCallback
  };
}

export function registerSuccess() {
  return {
    type: ACCOUNT_REGISTER_SUCCESS
  };
}

export function registerError(error) {
  return {
    type: ACCOUNT_REGISTER_ERROR,
    error
  };
}

export function confirmEmail(userId, code) {
  return {
    type: ACCOUNT_CONFIRM_EMAIL,
    userId: userId,
    code: code
  };
}

export function confirmEmailSuccess() {
  return {
    type: ACCOUNT_CONFIRM_EMAIL_SUCCESS
  };
}

export function confirmEmailError(error) {
  return {
    type: ACCOUNT_CONFIRM_EMAIL_ERROR,
    error
  };
}
