import React, {useEffect} from 'react';
import {useQuery} from "../../../utils/helpers";
import useTrackPageVisit from "../../../components/common/hooks/useTrackPageVisit";
import {useSelector} from 'react-redux';
import {makeSelectAuthenticateStatus} from '../selectors';
import {useNavigate} from 'react-router-dom';

const LoginPage = () => {
  useTrackPageVisit("Login ISH");
  const {loading, isAuthenticated} = useSelector(makeSelectAuthenticateStatus());
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    const redirectURL = query.get("url");

    if (redirectURL) {
      localStorage.setItem('redirectURL', decodeURIComponent(redirectURL));
    } else {
      localStorage.removeItem('redirectUrl');
    }

    if (!loading && !isAuthenticated) {
      let  u = `${process.env.GATEWAY}/api/Account/ExternalLogin?provider=e-commerce`;
      if (redirectURL) {
        u = u + `&redirectUrl=${encodeURIComponent(redirectURL)}`;
      }

      // eslint-disable-next-line no-console
      console.info("Redirecting window to authentication using e-commerce provider ", u);
      window.location.replace(u);
    } else if (!loading && isAuthenticated) {
      // eslint-disable-next-line no-console
      console.info("User already authenticated. Redirecting back to e-commerce.");
      window.location.replace(decodeURIComponent(redirectURL));
    }
  }, [isAuthenticated, loading, navigate, query]);

  return null;
};

export default React.memo(LoginPage);
