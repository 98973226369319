import React from 'react';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import {useSelector} from 'react-redux';
import { makeSelectUserProfilePackages } from '../selectors/userProfileSelectors';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';

const DemoLicenseBanner = () => {
  const {t} = useTranslation();
  const packages = useSelector(makeSelectUserProfilePackages());

  if (packages.includes('Demo')) {
    return (
      <Alert severity="warning" variant="standard" >
        <AlertTitle>{t("DemoLicenseBanner.WarningTitle", "Warning")}</AlertTitle>
        <Typography variant="body1">
          {t("DemoLicenseBanner.WarningText", "You are currently using the DEMO version of DThermX, which has certain features disabled. If you have recently registered your account, a sales representative will assign your license within three business days.")}
        </Typography>
      </Alert>
    );
  } else {
    return null;
  }
};

export default React.memo(DemoLicenseBanner);
