import axios from 'axios';
import * as links from '../constants/links';
import {datahallBaseAddress, dthermXBaseAddress} from './baseAddresses';

class UserProfileApi {
    static getUserProfileData() {
        return axios({
          method: 'GET',
            url: `${dthermXBaseAddress()}${links.USERPROFILE_GET}`,
        });
    }

    static getCountries() {
          return axios({ method: 'GET',
              url: `${process.env.GATEWAY}${links.CONSTANTS_COUNTRIES}/full`,
        });
    }

    static saveUserProfileData(userProfileData) {
        return axios({
          method: 'POST',
          url: `${dthermXBaseAddress()}${links.USERPROFILE_SAVE}`,
          data: userProfileData
        });
    }

    static saveUserProfile(settings) {
        return axios({
          method: 'POST',
            url: `${dthermXBaseAddress()}${links.USERPROFILE_SETTINGS_SAVE}`,
            data: {
                settings: settings
            }
        });
    }

    static changePassword(oldPassword, newPassword) {
        return axios({ method: 'POST',
            url: `${datahallBaseAddress()}${links.USERPROFILE_CHANGE_PWD}`,
            data: {
                oldPassword: oldPassword,
                newPassword: newPassword
            }
        });
    }
}

export default UserProfileApi;
