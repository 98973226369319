import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {styled} from 'styled-components';
import { Link, NavLink, matchPath } from 'react-router-dom';
import {generateCalculationPath, RouterPaths} from "../../containers/App/RouterPaths";
import { IconBlur, IconBasket, IconHome, IconSettings, IconGlobe, IconHammer, IconMulticalc } from "components/Icons";
import SideNav, {Toggle, Nav, NavItem, NavIcon, NavText} from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './sideBarStyle.scss';
import { useTranslation } from 'react-i18next';
import OpenInNewTabButton from "components/common/OpenInNewTabButton";
import XRefLogo from 'styles/images/X-R-logo.png';
import {
  SinglePhase,
  SinglePhaseDual,
  Condenser,
  CondenserDual,
  CondenserHeatPump,
  Evaporator,
  EvaporatorDual,
  EvaporatorHeatPump,
  LiquidEvaporator,
  Cascade,
  TwoStage,
  AirDryer,
  DistrictEnergy,
  AHRI,
  Hypertwain,
} from 'constants/calculationTypes';
import {WIKI_BASE} from "../../constants/links";
import {IsECommerceUser} from "../../containers/App/selectors";
import useUserProfile from '../common/hooks/useUserProfile';

const navWidthCollapsed = 64;
const navWidthExpanded = 290;

const NavHeader = styled.div`
    display: ${props => (props.expanded ? 'block' : 'none')};
`;

// const NavInfoPane = styled.div`
//     float: left;
//     width: 100%;
//     padding: 10px 20px;
//     background-color: #eee;
// `;

let Main = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: ${props => (props.expanded ? `${navWidthExpanded}px` : `${navWidthCollapsed}px`)};
    overflow: hidden;
    transition: all .15s;
    padding: 0 10px;
    // background: ${props => (props.expanded ? 'rgba(0, 0, 0, .6)' : 'inherit')};
    transition: background-color .35s cubic-bezier(.4, 0, .2, 1);
    border-left: 1px solid #ddd;
`;

// NavItem
const StyledNavItem = styled(NavItem)`
  &&&:hover {
    [class*="navtext--"] {
      color: #222;
    }
  },
  [class*="--navtext--"] {
    color: #222 !important;
  }
  [class*="--navtext--"] > * {
    color: #222 !important;
    text-decoration: none;
  }
`;
StyledNavItem.defaultProps = NavItem.defaultProps;

// NavIcon
const StyledNavIcon = styled(NavIcon)`
    color: #222;
`;
StyledNavIcon.defaultProps = NavIcon.defaultProps;

// NavText
const StyledNavText = styled(NavText)`
    color: #222;
`;
StyledNavText.defaultProps = NavText.defaultProps;

const implementedCalculations = [
  { calculationType: SinglePhase, translationKey: "SinglePhase" },
  { calculationType: AHRI, translationKey: "AHRI" },
  { calculationType: SinglePhaseDual, translationKey: "SinglePhaseDual" },
  { calculationType: Condenser, translationKey: "Condenser" },
  { calculationType: CondenserDual, translationKey: "CondenserDual" },
  { calculationType: CondenserHeatPump, translationKey: "CondenserHeatPump" },
  { calculationType: Evaporator, translationKey: "Evaporator" },
  { calculationType: EvaporatorDual, translationKey: "EvaporatorDual" },
  { calculationType: EvaporatorHeatPump, translationKey: "EvaporatorHeatPump" },
  { calculationType: LiquidEvaporator, translationKey: "LiquidEvaporator" },
  { calculationType: Cascade, translationKey: "Cascade" },
  { calculationType: TwoStage, translationKey: "TwoStage" },
  { calculationType: AirDryer, translationKey: "AirDryer" },
  { calculationType: DistrictEnergy, translationKey: "DEWindowTitle" },
  { calculationType: Hypertwain, translationKey: "Hypertwain" }
];

const SideNavBar = (props) => {
    const { t } = useTranslation();
    const userProfile = useUserProfile();
    // const location = useLocation();
    const isECommerceUser = IsECommerceUser();

    let matchedPath = 'home';
    Object.keys(RouterPaths).some(e => {
      const match = matchPath(RouterPaths[e], location.pathname);
      if (match) {
          matchedPath = match.path;
          return true;
      }
    });
    const [selected, setSelected] = useState(matchedPath);
    const [expanded, setExpanded] = useState(false);

    const onSelect = (selected) => {
        setSelected(selected);
    };
    const onToggle = (expanded) => {
        setExpanded(expanded);
    };

    const calculations = useMemo(() => {
      const upperCased = props.allowedCalculations.map(x => x.toUpperCase());
      return implementedCalculations.filter(x => upperCased.includes(x.calculationType.toUpperCase()));
    }, [props.allowedCalculations]);

  // console.log('SIDENAV: calculations', calculations)
    return (
      <>
        <SideNav
            className="styled-nav"
            style={{ minWidth: expanded ? navWidthExpanded : navWidthCollapsed }}
            onSelect={onSelect}
            onToggle={onToggle}>
            <Toggle className="nav-header" />
            <NavHeader className="nav-header" expanded={expanded}>
                <div className="nav-title">Menu</div>
                <div className="nav-subtitle">please select</div>
            </NavHeader>
            <Nav defaultSelected={selected}>
                <StyledNavItem eventKey="home" className="styled-nav-item" active={selected === 'home'}>
                    <NavIcon>
                        <Link to="/"><IconHome size={32} /></Link>
                    </NavIcon>
                    <NavText style={{ paddingRight: 32 }} title="HOME">
                        <Link to="/">{t("HamburgerHome")}</Link>
                    </NavText>
                </StyledNavItem>
                <StyledNavItem eventKey="calculations">
                    <NavIcon>
                        <IconBlur size={32} />
                    </NavIcon>
                    <NavText style={{ paddingRight: 32 }} title="CALCULATIONS">
                      {t("HamburgerCalculations")}
                    </NavText>
                    {calculations && calculations.map(({ calculationType, translationKey }, i) => (
                      <StyledNavItem key={i} eventKey={calculationType}>
                        <NavText title={t(translationKey)}>
                          <div className="d-flex justify-content-between">
                            <Link to={generateCalculationPath({
                              calculationType: calculationType,
                              uuid: 'new'
                            })}><span className="text-nowrap me-4">{t(translationKey)}</span></Link>
                            <OpenInNewTabButton size="small" path={generateCalculationPath({
                              calculationType: calculationType,
                              uuid: 'new'
                            })} />
                          </div>
                        </NavText>
                      </StyledNavItem>
                    ))}
                  {userProfile.isSavingCalculationsEnabled &&
                    <StyledNavItem eventKey={RouterPaths.SAVEDCALCULATIONS}>
                      <NavText title="Saved calculations">
                        <div className="d-flex justify-content-between">
                          <Link to={RouterPaths.SAVEDCALCULATIONS}><span className="text-nowrap me-4">{t("Saved calculations")}</span></Link>
                          <OpenInNewTabButton size="small" path="/savedcalculations" />
                        </div>
                      </NavText>
                    </StyledNavItem>
                  }
                </StyledNavItem>
              {!isECommerceUser && userProfile.isProductBasketEnabled && <StyledNavItem eventKey={RouterPaths.PRODUCTBASKET} active={selected === RouterPaths.PRODUCTBASKET}>
                <NavIcon>
                  <Link to={RouterPaths.PRODUCTBASKET}><IconBasket size={32}/></Link>
                </NavIcon>
                <StyledNavText style={{paddingRight: 32}} title="PRODUCT BASKET">
                  <Link to={RouterPaths.PRODUCTBASKET}>{t("HamburgerProductbasket")}</Link>
                </StyledNavText>
              </StyledNavItem>}
              <StyledNavItem eventKey="utils">
                <NavIcon>
                  <IconHammer size={32} />
                </NavIcon>
                <NavText style={{ paddingRight: 32 }} title="Utils">
                  {t("HamburgerTools")}
                </NavText>
                <NavItem eventKey={RouterPaths.FLUIDPROPERTIES}>
                  <NavText title={t("HamburgerFluidProperties")}>
                    <div className="d-flex justify-content-between">
                      <Link to={RouterPaths.FLUIDPROPERTIES}>{t("HamburgerFluidProperties")}</Link>
                      <OpenInNewTabButton size="small" path="/fluidproperties" />
                    </div>
                  </NavText>
                </NavItem>
                <NavItem eventKey={RouterPaths.FLUID_EDITOR}>
                  <NavText title={t("HamburgerFluidEditor")}>
                    <div className="d-flex justify-content-between">
                      <Link to={RouterPaths.FLUID_EDITOR}><span className="text-nowrap me-4">{t("HamburgerFluidEditor")}</span></Link>
                      <OpenInNewTabButton size="small" path="/fluidEditor" />
                    </div>
                  </NavText>
                </NavItem>
                <NavItem eventKey={RouterPaths.PRESSURE_DROP_TOOL}>
                  <NavText title={t("PD_PressureDrop")}>
                    <div className="d-flex justify-content-between">
                      <Link to={RouterPaths.PRESSURE_DROP_TOOL}><span className="text-nowrap me-4">{t("PD_PressureDrop")}</span></Link>
                      <OpenInNewTabButton size="small" path="/pressuredrop" />
                    </div>
                  </NavText>
                </NavItem>
                <NavItem eventKey={RouterPaths.CIP_TOOL}>
                  <NavText title={t("PD_CIP")}>
                    <div className="d-flex justify-content-between">
                      <Link to={RouterPaths.CIP_TOOL}><span className="text-nowrap me-4">{t("PD_CIP")}</span></Link>
                      <OpenInNewTabButton size="small" path="/cipTool" />
                    </div>
                  </NavText>
                </NavItem>
              </StyledNavItem>
              <StyledNavItem eventKey={RouterPaths.MULTICALC}>
                <NavIcon>
                  <Link to={RouterPaths.MULTICALC}><IconMulticalc size={32} /></Link>
                </NavIcon>
                <NavText style={{ paddingRight: 32 }} title="Multicalc">
                  <Link to={RouterPaths.MULTICALC}>{t("Multicalc")}</Link>
                </NavText>
                {userProfile.isSavingCalculationsEnabled &&
                  <NavItem eventKey={RouterPaths.MULTICALCSAVEDCALCULATIONS}>
                    <NavText title={t("PD_CIP")}>
                      <div className="d-flex justify-content-between">
                        <Link to={RouterPaths.MULTICALCSAVEDCALCULATIONS}><span className="text-nowrap me-4">{t("Saved calculations")}</span></Link>
                        <OpenInNewTabButton size="small" path="/multicalc-savedcalculations" />
                      </div>
                    </NavText>
                  </NavItem>
                }
              </StyledNavItem>
                {/*<StyledNavItem eventKey={RouterPaths.MULTICALC}>*/}
                {/*  <NavIcon>*/}
                {/*    <Link to="/multicalc"><IconMulticalc size={32} /></Link>*/}
                {/*  </NavIcon>*/}
                {/*  <NavText style={{ paddingRight: 32 }} title="Multicalc">*/}
                {/*    <Link to="/multicalc">{t("Multicalc")}</Link>*/}
                {/*  </NavText>*/}
                {/*</StyledNavItem>*/}
                <StyledNavItem title="Wiki" >
                  <NavIcon>
                    <Link to={WIKI_BASE} target="_blank">
                      <IconGlobe size={32} />
                    </Link>
                  </NavIcon>
                  <NavText style={{ paddingRight: 32 }} title="Wiki">
                    <Link to={WIKI_BASE} target="_blank">
                      Wiki
                    </Link>
                  </NavText>
                </StyledNavItem>
                <StyledNavItem title="X-Reference" eventKey={RouterPaths.XREFERENCE} active={selected === RouterPaths.XREFERENCE} >
                  <NavIcon>
                    <NavLink to={RouterPaths.XREFERENCE}>
                      <img src={XRefLogo} height={32} />
                    </NavLink>
                  </NavIcon>
                  <NavText style={{ paddingRight: 32 }} title="X-Reference">
                    <Link to={RouterPaths.XREFERENCE}>{t("X-Reference")}</Link>
                  </NavText>
                </StyledNavItem>
              <StyledNavItem eventKey={RouterPaths.SETTINGS} active={selected === RouterPaths.SETTINGS}>
                <NavIcon>
                  <Link to={RouterPaths.SETTINGS}><IconSettings size={32} /></Link>
                </NavIcon>
                <NavText style={{ paddingRight: 32 }} title="SETTINGS">
                  <Link to={RouterPaths.SETTINGS}>{t("Settings")}</Link>
                </NavText>
              </StyledNavItem>
            </Nav>
        </SideNav>
        <Main expanded={expanded} className="sidenav-main">
            {props.children}
        </Main>
      </>
    );
};

SideNavBar.propTypes = {
    location: PropTypes.object,
    children: PropTypes.object,
    allowedCalculations: PropTypes.array
};

export default React.memo(SideNavBar);
