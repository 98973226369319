import React from 'react';
import UserMenu from 'components/user/userMenu/UserMenu';
import SwepLogo from 'styles/images/swep-logo.svg';
import SSPOnline from 'styles/images/online_logo_white.png';
import {IsECommerceUser} from "../../containers/App/selectors";

const NavBar = () => {
  const isECommerceUser = IsECommerceUser();

  if (isECommerceUser) {
    return null;
  } else {
    return (
      <div>
        <nav className="navbar navbar-expand-md navbar-light">
          <div className="container-fluid">
            <a className="navbar-brand" href="/"><img alt="SSP Online" className="ssp-web-logo" src={SSPOnline} style={{height:'40px'}} /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
                    aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <UserMenu />
                </li>
                <li className="nav-item">
                  <a className="navbar-brand" href="#" ><img alt="SSP" className="ssp-web-logo" src={SwepLogo} style={{height:'40px'}} /></a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
};

// NavBar.propTypes

export default NavBar;
