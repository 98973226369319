import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import NavBar from '../../components/header/NavBar';
import SideNavBar from '../../components/sideNav/SideNavBar';
import { makeSelectUserAllowedCalculations } from '../../selectors/userProfileSelectors';
import DemoLicenseBanner from '../../components/DemoLicenseBanner';
import {Box} from '@mui/material';
import {makeSelectIsAuthenticated} from '../Account/selectors';

const SharedLayout = () => {
  const isAuth = useSelector(makeSelectIsAuthenticated());
  const allowedCalculations = useSelector(makeSelectUserAllowedCalculations());

  return (
    <>
      {isAuth && <NavBar/>}
      <div
        style={{
          position: 'relative',
          height: 'calc(100vh - 66px)'
        }}>
        {isAuth && <SideNavBar allowedCalculations={allowedCalculations}>
          <Box sx={{ pb: 3 }}>
            <DemoLicenseBanner/>
          </Box>
          <Outlet />
        </SideNavBar> || <Outlet/>}
      </div>
    </>
  );
};

export default React.memo(SharedLayout);
