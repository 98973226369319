import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import { useSelector} from 'react-redux';
import {RouterPaths} from '../containers/App/RouterPaths';
import {makeSelectAuthenticateStatus} from '../containers/Account/selectors';
import LoadingPanel from './LoadingPanel';
import ErrorPanel from './common/ErrorPanel/ErrorPanel';

const PrivateRoute = () => {
  const location = useLocation();
  const { loading, error, isAuthenticated} = useSelector(makeSelectAuthenticateStatus());

  return (
    <LoadingPanel isLoading={loading}>
      <ErrorPanel error={error}>
        {isAuthenticated === true ? <Outlet/> : <Navigate to={RouterPaths.LOGIN} replace state={{ from: location }} />}
      </ErrorPanel>
    </LoadingPanel>
  );
};

export default React.memo(PrivateRoute);
