import axios from 'axios';
import {
  ACCOUNT_AUTHENTICATE_STATUS_URL,
  ACCOUNT_CONFIRM_EMAIL_URL,
  ACCOUNT_FORGOT_PASSWORD_URL,
  ACCOUNT_LOGIN_URL,
  ACCOUNT_LOGOUT_URL,
  ACCOUNT_REGISTER_URL,
  ACCOUNT_RESET_PASSWORD_URL
} from './links';

export function login(username, password) {
  if (!username) {
    return new Promise((resolve, reject) => {
      reject('Request validation error - `username` is missing');
    });
  }

  if (!password) {
    return new Promise((resolve, reject) => {
      reject('Request validation error - `password` is missing');
    });
  }

  const request = {
    email: username,
    password: password
  };

  return axios({
    method: 'POST',
    url: `${process.env.GATEWAY}${ACCOUNT_LOGIN_URL}`,
    data: request
  });
}

export function logout() {
  return axios({
    method: 'POST',
    url: `${process.env.GATEWAY}${ACCOUNT_LOGOUT_URL}`,
    withCredentials: true
  });
}

export function authenticateStatus() {
  return axios({
    method: 'GET',
    url: `${process.env.GATEWAY}${ACCOUNT_AUTHENTICATE_STATUS_URL}`,
    withCredentials: true
  });
}

export const forgotPassword = (email) => {
  if(!email){
    return new Promise((resolve, reject) => {
      reject('Request validation error - `email` is missing');
    });
  }
  const request= {
    email: email,
  };
  return axios({
    method: 'POST',
    url: `${process.env.GATEWAY}${ACCOUNT_FORGOT_PASSWORD_URL}`,
    data: request,
    withCredentials: true
  });
};

export const resetPassword = (email, resetCode, newPassword) => {
  if (!email) {
    return new Promise((resolve, reject) => {
      reject('Request validation error - `email` is missing');
    });
  }

  if (!resetCode) {
    return new Promise((resolve, reject) => {
      reject('Request validation error - `resetCode` is missing');
    });
  }

  if (!newPassword) {
    return new Promise((resolve, reject) => {
      reject('Request validation error - `newPassword` is missing');
    });
  }

  return axios({
    method: 'POST',
    url: `${process.env.GATEWAY}${ACCOUNT_RESET_PASSWORD_URL}`,
    data: {
      email: email,
      resetCode: resetCode,
      newPassword: newPassword
    }
  });
};

export const register = (request) => {
  if(!request){
    return new Promise((resolve, reject) => {
      reject('Request validation error');
    });
  }

  if(!request.firstName || !request.secondName || !request.email || !request.password || !request.country || !request.company || !request.phone){
    return new Promise((resolve, reject) => {
      reject('Request validation error');
    });
  }
  return axios({
    method: 'POST',
    url: `${process.env.GATEWAY}${ACCOUNT_REGISTER_URL}`,
    data: {
      email: request.email,
      password: request.password,
      firstName: request.firstName,
      lastName: request.secondName,
      company: request.company,
      country: request.country,
      phoneNumber: request.phone
    }
  });
};

export const confirmEmail = (userId, code) => {
  if (!userId) {
    return new Promise((resolve, reject) => {
      reject('Request validation error - `userId` is missing');
    });
  }

  if (!code) {
    return new Promise((resolve, reject) => {
      reject('Request validation error - `code` is missing');
    });
  }

  return axios({
    method: 'GET',
    url: `${process.env.GATEWAY}${ACCOUNT_CONFIRM_EMAIL_URL}`,
    params: {
      userId: userId,
      code: code
    }
  });
};
