export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const AUTHENTICATE_STATUS = 'AUTHENTICATE_STATUS';
export const AUTHENTICATE_STATUS_SUCCESS = 'AUTHENTICATE_STATUS_SUCCESS';
export const AUTHENTICATE_STATUS_ERROR = 'AUTHENTICATE_STATUS_ERROR';

export const ACCOUNT_FORGOTTEN_PASSWORD = 'ACCOUNT_FORGOTTEN_PASSWORD';
export const ACCOUNT_FORGOTTEN_PASSWORD_SUCCESS = 'ACCOUNT_FORGOTTEN_PASSWORD_SUCCESS';
export const ACCOUNT_FORGOTTEN_PASSWORD_ERROR = 'ACCOUNT_FORGOTTEN_PASSWORD_ERROR';

export const ACCOUNT_RESET_PASSWORD = 'ACCOUNT_RESET_PASSWORD';
export const ACCOUNT_RESET_PASSWORD_SUCCESS = 'ACCOUNT_RESET_PASSWORD_SUCCESS';
export const ACCOUNT_RESET_PASSWORD_ERROR = 'ACCOUNT_RESET_PASSWORD_ERROR';

export const ACCOUNT_REGISTER = 'ACCOUNT_REGISTER';
export const ACCOUNT_REGISTER_SUCCESS = 'ACCOUNT_REGISTER_SUCCESS';
export const ACCOUNT_REGISTER_ERROR = 'ACCOUNT_REGISTER_ERROR';

export const ACCOUNT_CONFIRM_EMAIL = 'ACCOUNT_CONFIRM_EMAIL';
export const ACCOUNT_CONFIRM_EMAIL_SUCCESS = 'ACCOUNT_CONFIRM_EMAIL_SUCCESS';
export const ACCOUNT_CONFIRM_EMAIL_ERROR = 'ACCOUNT_CONFIRM_EMAIL_ERROR';
