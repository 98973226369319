import {
  ACCOUNT_CONFIRM_EMAIL,
  ACCOUNT_CONFIRM_EMAIL_ERROR,
  ACCOUNT_CONFIRM_EMAIL_SUCCESS,
  ACCOUNT_RESET_PASSWORD,
  ACCOUNT_RESET_PASSWORD_ERROR,
  ACCOUNT_RESET_PASSWORD_SUCCESS,
  AUTHENTICATE_STATUS,
  AUTHENTICATE_STATUS_ERROR,
  AUTHENTICATE_STATUS_SUCCESS,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS, LOGOUT, LOGOUT_ERROR, LOGOUT_SUCCESS
} from './contants';
import {produce} from 'immer';

export const initialState = {
  login: {
    loading: false,
    error: false,
    success: false
  },
  logout: {
    loading: false,
    error: false,
    success: false
  },
  authenticateStatus: {
    loading: false,
    error: false,
    isAuthenticated: false
  },
  resetPassword: {
    loading: false,
    error: false,
    success: false
  },
  confirmEmail: {
    loading: false,
    error: false,
    success: false
  }
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOGIN:
        draft.login.loading = true;
        draft.login.error = false;
        draft.login.success = false;
        break;
      case LOGIN_SUCCESS:
        draft.login.loading = false;
        draft.login.error = false;
        draft.login.success = true;

        draft.authenticateStatus.isAuthenticated = true;
        break;
      case LOGIN_ERROR:
        draft.login.loading = false;
        draft.login.error = action.error;
        draft.login.success = false;

        draft.authenticateStatus.isAuthenticated = false;
        break;
      case LOGOUT:
        draft.logout.loading = true;
        draft.logout.error = false;
        draft.logout.success = false;
        break;
      case LOGOUT_SUCCESS:
        draft.logout.loading = false;
        draft.logout.error = false;
        draft.logout.success = true;

        draft.authenticateStatus.isAuthenticated = false;
        break;
      case LOGOUT_ERROR:
        draft.logout.loading = false;
        draft.logout.error = action.error;
        draft.logout.success = false;

        draft.authenticateStatus.isAuthenticated = false;
        break;
      case AUTHENTICATE_STATUS:
        draft.authenticateStatus.loading = true;
        draft.authenticateStatus.error = false;
        //draft.authenticateStatus.isAuthenticated = false;
        break;
      case AUTHENTICATE_STATUS_SUCCESS:
        draft.authenticateStatus.loading = false;
        draft.authenticateStatus.error = false;
        draft.authenticateStatus.isAuthenticated = true;
        break;
      case AUTHENTICATE_STATUS_ERROR:
        draft.authenticateStatus.loading = false;
        draft.authenticateStatus.error = action.error;
        draft.authenticateStatus.isAuthenticated = false;
        break;
      case ACCOUNT_RESET_PASSWORD:
        draft.resetPassword.loading = true;
        draft.resetPassword.error = false;
        draft.resetPassword.success = false;
        break;
      case ACCOUNT_RESET_PASSWORD_SUCCESS:
        draft.resetPassword.loading = false;
        draft.resetPassword.error = false;
        draft.resetPassword.success = true;
        break;
      case ACCOUNT_RESET_PASSWORD_ERROR:
        draft.resetPassword.loading = false;
        draft.resetPassword.error = action.error;
        draft.resetPassword.success = false;
        break;
      case ACCOUNT_CONFIRM_EMAIL:
        draft.confirmEmail.loading = true;
        draft.confirmEmail.error = false;
        draft.confirmEmail.success = false;
        break;
      case ACCOUNT_CONFIRM_EMAIL_SUCCESS:
        draft.confirmEmail.loading = false;
        draft.confirmEmail.error = false;
        draft.confirmEmail.success = true;
        break;
      case ACCOUNT_CONFIRM_EMAIL_ERROR:
        draft.confirmEmail.loading = false;
        draft.confirmEmail.error = action.error;
        draft.confirmEmail.success = false;
        break;
    }
  });

export default reducer;
export const ReducerKey = "account";
